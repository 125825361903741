import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  fetchKpi,
  fetchPegawai,
  fetchingData,
  patchKpi,
  patchKpiAudit,
} from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  FilterCabang,
  FilterPagination,
  FilterSearch,
  FilterTahun,
} from "../../components/layouts/TopContent";
import PageNoData from "../../components/pages/NoData";
import SectionPagination from "../../components/sections/Pagination";
import { tahunAjaranState } from "../../storage/mhs";
import { titleState } from "../../storage/pages";
import { getCurrency, saAlert, saConfirm } from "../../helpers";
import LayoutModal from "../../components/layouts/Modal";
import { userState } from "../../storage/auth";

const KpiAuditor = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const user = useRecoilValue(userState);

  const [dataPegawai, setDataPegawai] = useState({});

  const tA = useRecoilValue(tahunAjaranState);

  const [page, setPage] = useState(1);
  const [cabang, setCabang] = useState("");
  const [tahun, setTahun] = useState(tA);
  const [pagination, setPagination] = useState("10");
  const [search, setSearch] = useState("");

  const setTitle = useSetRecoilState(titleState);

  useEffect(() => {
    setTitle("Data Pegawai");
  }, [setTitle]);

  const fetchData = useCallback(
    async (reset = true) => {
      if (reset) {
        setIsLoaded(false);
      }
      try {
        const res = await fetchPegawai(
          page,
          `search=${search}&cabang=${cabang}&tahun=${tahun}&pagination=${pagination}`
        );
        setDataPegawai(res.data.pegawai);
        setIsLoaded(true);
      } catch (err) {
        console.log(err);
      }
    },
    [page, cabang, tahun, pagination, search]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (user.is_auditor) {
    return (
      <>
        <div className="row mb-2">
          <div className="col-3">
            <FilterTahun
              value={tahun}
              onChange={(res) => {
                setTahun(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-3">
            <FilterCabang
              pusat={true}
              onChange={(res) => {
                setCabang(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-3">
            <FilterPagination
              onChange={(res) => {
                setPagination(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-3">
            <FilterSearch
              onChange={(res) => {
                setSearch(res);
                setPage(1);
              }}
            />
          </div>
        </div>
        {isLoaded && dataPegawai.data && dataPegawai.data.length > 0 ? (
          <LayoutTable>
            <LayoutThead tr={1}>
              <tr className="text-center">
                <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle">No</th>
                <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle">NIP</th>
                <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle">Nama</th>
                <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle">Cabang</th>
                <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle">Jabatan</th>
                <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle">Group</th>
                <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle">Grade</th>
              </tr>
            </LayoutThead>
            <tbody>
              {dataPegawai.data.map((i, index) => {
                return (
                  <tr key={index}>
                    <td className="py-1 border-silver text-center">{dataPegawai.from + index}</td>
                    <td className="py-1 border-silver text-nowrap text-center">{i.nip}</td>
                    <td className="py-1 border-silver text-nowrap">{i.nama}</td>
                    <td className="py-1 border-silver text-center">{i.cabang}</td>
                    <td className="py-1 border-silver">
                      <AuditNow pegawai={i} tahun={tahun} />
                    </td>
                    <td className="py-1 border-silver text-center">{i.group}</td>
                    <td className="py-1 border-silver text-center">{i.grade}</td>
                  </tr>
                );
              })}
            </tbody>
          </LayoutTable>
        ) : (
          <PageNoData />
        )}
        <SectionPagination
          links={dataPegawai.links}
          onChangePage={(res) => setPage(res)}
        />
      </>
    );
  }

  return (
    <div className="text-white bg-gradient-primary border-bottom-warning rounded-3 p-4 text-justify my-3">
      <div className="position-relative border border-secondary rounded p-3">
        <div className="text-animate position-absolute bg-gradient-warning text-black rounded fw-semibold px-3" style={{rotate: '-4deg', left: '-15px', top: '-10px'}}><i className="fa fa-fw fa-bell fa-shake"/> Informasi Penting !</div>
        <h6 className="mb-0 mt-2">
          Anda tidak memiliki akses untuk mengaudit, hubungi HRD untuk detail lebih lanjut.
        </h6>
      </div>
    </div>
  );
};

const AuditNow = ({ pegawai, tahun }) => {
  const user = useRecoilValue(userState);

  const [modal, setModal] = useState(false);
  const [kpi, setKpi] = useState([]);

  const [pegawaiJabatanAll, setPegawaiJabatanAll] = useState([]);

  useEffect(() => {
    setPegawaiJabatanAll(
      pegawai.pegawai_jabatan && pegawai.pegawai_jabatan.length > 0
        ? pegawai.pegawai_jabatan
            .filter((pj) => !pj.tanggal_berakhir)
            .map((pj) => pj)
        : []
    );
  }, [pegawai]);

  const [pegawaiJabatanEdited, setPegawaiJabatanEdited] = useState({});

  const fetchData = async (jabatan_id) => {
    const res = await fetchKpi("all", `jabatan_id=${jabatan_id}&tahun=${tahun}`);
    if (res.data) {
      setKpi(res.data.kpi);
    }
  };

  const handleOpen = (pj) => {
    setPegawaiJabatanEdited(pj);
    fetchData(pj.jabatan_id);
    setModal(true);
  };

  const handleClose = () => {
    setKpi([]);
    setModal(false);
    setPegawaiJabatanEdited({});
  };

  let timer = 0;
  const handleChange = (id, pegawai_id, kpi_id, jabatan_id, name, value) => {
    clearTimeout(timer);

    let type = {};

    if (name === "capaian") {
      type = { is_capaian: true };
    } else if (name === "nilai") {
      type = { is_nilai: true };
    }

    timer = setTimeout(async () => {
      try {
        const res = await patchKpi(id, {
          [name]: value,
          pegawai_id,
          kpi_id,
          jabatan_id,
          ...type,
        });
        if (res.data && res.data.status) {
          fetchingData(res.data.status, res.data.msg);
        }
      } catch (err) {
        err.response.status === 422 &&
          saAlert(
            "warning",
            "Hanya angka, tanpa simbol / karakter lain",
            err.response.data.errors[name]
          );
      }
    }, 1000);
  };

  const getClassName = (k) => {
    if (
      k.kpi_audit &&
      k.kpi_audit.filter(
        (ka) => parseInt(ka.pegawai_id) === parseInt(pegawai.id)
      ).length > 0
    ) {
      if (
        parseInt(
          k.kpi_audit.filter(
            (ka) => parseInt(ka.pegawai_id) === parseInt(pegawai.id)
          )[0].status
        ) === 1
      ) {
        return "bg-info text-white";
      } else if (
        parseInt(
          k.kpi_audit.filter(
            (ka) => parseInt(ka.pegawai_id) === parseInt(pegawai.id)
          )[0].status
        ) === 2
      ) {
        return "bg-danger text-white";
      }
    }
    return "";
  };

  const getValidation = (k) => {
    if (
      k.kpi_audit &&
      k.kpi_audit.filter(
        (ka) => parseInt(ka.pegawai_id) === parseInt(pegawai.id)
      ).length > 0
    ) {
      if (
        parseInt(
          k.kpi_audit.filter(
            (ka) => parseInt(ka.pegawai_id) === parseInt(pegawai.id)
          )[0].status
        ) === 1
      ) {
        return (
          <div className="d-inline-block bg-black text-center shadow rounded-3 p-2">
            <i className="fa fa-fw fs-1 fa-circle-check text-success-2"/>
            <p className="mb-0 mt-2">Disetujui</p>
          </div>
        );
      } else if (
        parseInt(
          k.kpi_audit.filter(
            (ka) => parseInt(ka.pegawai_id) === parseInt(pegawai.id)
          )[0].status
        ) === 2
      ) {
        return (
          <div className="d-inline-block bg-black text-center shadow rounded-3 p-2">
            <i className="fa fa-fw fs-1 fa-circle-xmark text-danger-2"/>
            <p className="mb-0 mt-2">Ditolak</p>
          </div>
        );
      }
    }
    return (
      <div className="d-inline-block bg-black text-center shadow text-secondary rounded-3 p-2">
        <i className="fa fa-fw fs-1 fa-circle-question fa-regular"/>
        <p className="mb-0 mt-2">status</p>
      </div>
    );
  };

  return (
    <>
      {pegawaiJabatanAll.length > 0 &&
        pegawaiJabatanAll.map((pj, index) => {
          return (
            <span
              type="button"
              key={index}
              className="btn btn-sm btn-primary-3 py-0 px-2 text-sm mr-2"
              onClick={() => handleOpen(pj)}
            >
              <i className="fa fa-cog mr-1"></i>
              Audit As{` `}
              {pj.jabatan && pj.jabatan.nama}
            </span>
          );
        })}
      <LayoutModal 
        title={`Audit KPI (${pegawai.nama})`} 
        modal={modal} 
        onClose={handleClose}
      >
        {kpi.length > 0 &&
          kpi.map((k, index) => {
            return (
            <div className="position-relative bg-gradient-primary text-white rounded-3 shadow mb-3" key={index}>
              <div className="pb-3">
                <span className="position-absolute bg-warning text-black rounded fw-semibold shadow px-3">No. {index + 1}</span>
              </div>
              <div className="bg-gradient-primary border-bottom-warning rounded-top-3 p-3">
                <div className="row">
                  <div className="col-lg-1 mb-3 d-flex justify-content-start align-items-center">
                    {getValidation(k)}
                  </div>
                  <div className="col-lg-11 mb-3">
                    <p className="mb-0 text-warning">Judul :</p>
                    <h6>{k.nama}</h6>

                    <p className="mb-0 text-warning">Keterangan :</p>
                    <p className="mb-0">{k.keterangan}</p>
                  </div>
                </div>
              </div>

              <div className="p-3 mx-3">
                <table className="table table-sm table-bordered table-hover">
                  <tbody>
                    <tr>
                      <td className="py-1 border-dark align-middle text-gray-400">Pegawai</td>
                      <td className="py-1 border-dark align-middle text-white" style={{width: '85%'}}>{pegawai.nama}</td>
                    </tr>
                    <tr>
                      <td className="py-1 border-dark align-middle text-gray-400">Self Assesment</td>
                      <td className="py-1 border-dark align-middle text-white" style={{width: '85%'}}>
                      {k.kpi_audit &&
                        k.kpi_audit
                          .filter(
                            (ka) =>
                              parseInt(ka.pegawai_id) === parseInt(pegawai.id)
                          )
                          .map((ka) => ka.self_assesment || "0")}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-1 border-dark align-middle text-gray-400">Capaian <i className="fa fa-fw fa-sm fa-pen text-success-2"/></td>
                      <td className="py-0 border-dark align-middle text-white" style={{width: '85%'}}>
                        <input
                          className="form-control form-control-sm form-control-custom p-0 bg-none text-white border-0"
                          type="text"
                          name="capaian"
                          defaultValue={
                            k.kpi_audit &&
                            k.kpi_audit
                              .filter(
                                (ka) =>
                                  parseInt(ka.pegawai_id) === parseInt(pegawai.id)
                              )
                              .map((ka) => ka.capaian ? (ka.capaian % 1 !== 0 ? ka.capaian.toFixed(2) : ka.capaian) : "0")
                          }
                          onChange={(e) => {
                            let pegawai_id = pegawai.id;
                            let kpi_id = k.id;
                            let jabatan_id = k.jabatan_id;

                            handleChange(
                              k.id,
                              parseInt(pegawai_id),
                              parseInt(kpi_id),
                              parseInt(jabatan_id),
                              e.target.name,
                              e.target.value
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="py-1 border-dark align-middle text-gray-400">Target</td>
                      <td className="py-1 border-dark align-middle text-white" style={{width: '85%'}}>
                      {k.kpi_audit &&
                        k.kpi_audit
                          .filter(
                            (ka) =>
                              parseInt(ka.pegawai_id) === parseInt(pegawai.id)
                          )
                          .map((ka) =>
                            ka.satuan === "Rupiah"
                              ? getCurrency(ka.target)
                              : ka.target
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-1 border-dark align-middle text-gray-400">Satuan</td>
                      <td className="py-1 border-dark align-middle text-white" style={{width: '85%'}}>
                      {k.kpi_audit &&
                        k.kpi_audit
                          .filter(
                            (ka) =>
                              parseInt(ka.pegawai_id) === parseInt(pegawai.id)
                          )
                          .map((ka) => ka.satuan)}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-1 border-dark align-middle text-gray-400">Bobot</td>
                      <td className="py-1 border-dark align-middle text-white" style={{width: '85%'}}>
                      {k.kpi_audit &&
                        k.kpi_audit
                          .filter(
                            (ka) =>
                              parseInt(ka.pegawai_id) === parseInt(pegawai.id)
                          )
                          .map((ka) => ka.bobot)}
                      </td>
                    </tr>
                    {(() => {
                      if (
                        user.jabatan === "IT Programmer" ||
                        user.jabatan === "Bagian SDM"
                      ) {
                        return (
                          <tr>
                            <td className="py-1 border-dark align-middle text-gray-400">Nilai <i className="fa fa-fw fa-sm fa-pen text-success-2"/></td>
                            <td className="py-0 border-dark align-middle text-white" style={{width: '85%'}}>
                            <input
                              className="form-control form-control-sm form-control-custom p-0 bg-none text-warning border-0"
                              type="text"
                              name="nilai"
                              defaultValue={
                                k.kpi_audit &&
                                k.kpi_audit
                                  .filter(
                                    (ka) =>
                                      parseInt(ka.pegawai_id) ===
                                      parseInt(pegawai.id)
                                  )
                                  .map((ka) => ka.nilai ? (ka.nilai % 1 !== 0 ? ka.nilai.toFixed(2) : ka.nilai) : "0")
                              }
                              onChange={(e) => {
                                let pegawai_id = pegawai.id;
                                let kpi_id = k.id;
                                let jabatan_id = k.jabatan_id;

                                handleChange(
                                  k.id,
                                  parseInt(pegawai_id),
                                  parseInt(kpi_id),
                                  parseInt(jabatan_id),
                                  e.target.name,
                                  e.target.value
                                );
                              }}
                            />
                            </td>
                          </tr>
                        );
                      }
                    })()}
                  </tbody>
                </table>

                {(() => {
                  if (
                    user.jabatan === "IT Programmer" ||
                    user.jabatan === "Bagian SDM"
                  ) {
                    return (
                      <Validator
                        kpi_audit={
                          k.kpi_audit &&
                          k.kpi_audit.filter(
                            (ka) =>
                              parseInt(ka.pegawai_id) ===
                              parseInt(pegawai.id)
                          )
                        }
                        onFetchData={() => {
                          pegawaiJabatanEdited.jabatan_id &&
                            fetchData(pegawaiJabatanEdited.jabatan_id);
                        }}
                      />
                    );
                  }
                })()}
              </div>
            </div>
            )
          })
        }


        {/* <LayoutTable>
          <LayoutThead>
            <th>No</th>
            <th>Pegawai</th>
            <th>Judul</th>
            <th>Keterangan</th>
            <th>Self Assignment</th>
            <th>Capaian</th>
            <th>Target</th>
            <th>Satuan</th>
            <th>Bobot</th>
            {(() => {
              if (
                user.jabatan === "IT Programmer" ||
                user.jabatan === "Bagian SDM"
              ) {
                return (
                  <>
                    <th>Nilai</th>
                    <th>Validation</th>
                  </>
                );
              }
            })()}
          </LayoutThead>
          <tbody>
            {kpi.length > 0 &&
              kpi.map((k, index) => {
                return (
                  <tr key={index} className={getClassName(k)}>
                    <td className={`${getClassName(k)} text-center`}>{index + 1}</td>
                    <td className={`${getClassName(k)}`}>{pegawai.nama}</td>
                    <td className={`${getClassName(k)}`}>{k.nama}</td>
                    <td className={`${getClassName(k)}`}>{k.keterangan}</td>
                    <td className={`${getClassName(k)} text-center`}>
                      {k.kpi_audit &&
                        k.kpi_audit
                          .filter(
                            (ka) =>
                              parseInt(ka.pegawai_id) === parseInt(pegawai.id)
                          )
                          .map((ka) => ka.self_assesment || "0")}
                    </td>
                    <td className={`${getClassName(k)}`}>
                      <input
                        type="text"
                        name="capaian"
                        defaultValue={
                          k.kpi_audit &&
                          k.kpi_audit
                            .filter(
                              (ka) =>
                                parseInt(ka.pegawai_id) === parseInt(pegawai.id)
                            )
                            .map((ka) => ka.capaian || "0")
                        }
                        onChange={(e) => {
                          let pegawai_id = pegawai.id;
                          let kpi_id = k.id;
                          let jabatan_id = k.jabatan_id;

                          handleChange(
                            k.id,
                            parseInt(pegawai_id),
                            parseInt(kpi_id),
                            parseInt(jabatan_id),
                            e.target.name,
                            e.target.value
                          );
                        }}
                      />
                    </td>
                    <td className={`${getClassName(k)}`}>
                      {k.kpi_audit &&
                        k.kpi_audit
                          .filter(
                            (ka) =>
                              parseInt(ka.pegawai_id) === parseInt(pegawai.id)
                          )
                          .map((ka) =>
                            ka.satuan === "Rupiah"
                              ? getCurrency(ka.target)
                              : ka.target
                          )}
                    </td>
                    <td className={`${getClassName(k)}`}>
                      {k.kpi_audit &&
                        k.kpi_audit
                          .filter(
                            (ka) =>
                              parseInt(ka.pegawai_id) === parseInt(pegawai.id)
                          )
                          .map((ka) => ka.satuan)}
                    </td>
                    <td className={`${getClassName(k)}`}>
                      {k.kpi_audit &&
                        k.kpi_audit
                          .filter(
                            (ka) =>
                              parseInt(ka.pegawai_id) === parseInt(pegawai.id)
                          )
                          .map((ka) => ka.bobot)}
                    </td>
                    {(() => {
                      if (
                        user.jabatan === "IT Programmer" ||
                        user.jabatan === "Bagian SDM"
                      ) {
                        return (
                          <>
                            <td className={`${getClassName(k)}`}>
                              <input
                                type="text"
                                name="nilai"
                                defaultValue={
                                  k.kpi_audit &&
                                  k.kpi_audit
                                    .filter(
                                      (ka) =>
                                        parseInt(ka.pegawai_id) ===
                                        parseInt(pegawai.id)
                                    )
                                    .map((ka) => ka.nilai || "0")
                                }
                                onChange={(e) => {
                                  let pegawai_id = pegawai.id;
                                  let kpi_id = k.id;
                                  let jabatan_id = k.jabatan_id;

                                  handleChange(
                                    k.id,
                                    parseInt(pegawai_id),
                                    parseInt(kpi_id),
                                    parseInt(jabatan_id),
                                    e.target.name,
                                    e.target.value
                                  );
                                }}
                              />
                            </td>
                            <td className={`${getClassName(k)} text-center`}>
                              <Validator
                                kpi_audit={
                                  k.kpi_audit &&
                                  k.kpi_audit.filter(
                                    (ka) =>
                                      parseInt(ka.pegawai_id) ===
                                      parseInt(pegawai.id)
                                  )
                                }
                                onFetchData={() => {
                                  pegawaiJabatanEdited.jabatan_id &&
                                    fetchData(pegawaiJabatanEdited.jabatan_id);
                                }}
                              />
                            </td>
                          </>
                        );
                      }
                    })()}
                  </tr>
                );
              })}
          </tbody>
        </LayoutTable> */}
      </LayoutModal>
    </>
  );
};

const Validator = ({ kpi_audit, onFetchData }) => {
  const handleStatus = async (id, status) => {
    const res = await patchKpiAudit(id, { status });
    if (res.data && res.data.status) {
      // saAlert(res.data.status, res.data.msg);
      onFetchData();
    }
  };

  if (kpi_audit.length === 1) {
    return (
      <div className="row">
        <div className="col-lg-4 btn-group">
          <button
            type="button"
            onClick={() => handleStatus(kpi_audit[0].id, 1)}
            className="btn btn-sm btn-success-2 py-0 text-sm"
          >
            <i className="fa fa-fw fa-check"/> Menyetujui
          </button>
          <button
            type="button"
            onClick={() => handleStatus(kpi_audit[0].id, 2)}
            className="btn btn-sm btn-danger-2 py-0 text-sm"
          >
            <i className="fa fa-fw fa-xmark"/> Tolak
          </button>
        </div>
      </div>
    );
  } else {
    return "Terjadi kesalahan, segera hubungi bagian IT.";
  }
};

export default KpiAuditor;
