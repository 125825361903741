import React from "react";
import { useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { patchPojt } from "../../api";
import FormInput from "../../components/forms/Input";
import FormTextarea from "../../components/forms/Textarea";
import LayoutModal, { NewSubmitButton } from "../../components/layouts/Modal";
import { getJenjang, saAlert } from "../../helpers";
import { userState } from "../../storage/auth";
import { JurusanSelect } from "../perusahaan/Components";
import FormSelect from "../../components/forms/Select";

const PojtEdit = (props) => {
  const user = useRecoilValue(userState);

  const { perusahaan, pojt, jurusan, onFetchData } = props;
  const tagForm = useRef();

  const [modal, setModal] = useState(false);

  const [jurusanSelect, setJurusanSelect] = useState([]);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleOpen = () => {
    setForm(pojt);
    setModal(true);
  };

  const handleClose = () => {
    tagForm.current && tagForm.current.reset();
    setForm({});
    setJurusanSelect([]);
    setModal(false);
    setErrors({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});

    if (
      user.cabang === "pusat" ||
      perusahaan.cabang.toLowerCase() === user.cabang.toLowerCase()
    ) {
      try {
        const res = await patchPojt(form.id, {
          ...form,
          jurusan_select: jurusanSelect,
        });
        console.log(res.data);
        if (res.data === "success") {
          onFetchData();
          handleClose();
          saAlert("success", "Berhasil update Data !");
        }
      } catch (err) {
        console.error(err);
        setErrors(err.response && err.response.data && err.response.data.errors);
      }
    } else {
      saAlert("warning", "Tidak dapat mengubah data cabang lain.");
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-sm btn-success-2 text-sm py-0 px-1 mx-1"
        onClick={() => handleOpen()}
      >
        <i className="fa fa-fw fa-sm fa-pen"></i>
      </button>
      <LayoutModal
        title={`Ubah Data Perusahaan OJT ${perusahaan.nama}`}
        modal={modal}
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit} ref={tagForm}>
          <div className="row">
            <div className="col-lg-12 mb-3">
              <div className="bg-gradient-primary text-white rounded shadow p-3">
                <div>
                  <h5 className="mb-0">{perusahaan.nama}</h5>
                  <p className="mb-2 text-gray-400">{perusahaan.alamat}</p>
                  <p className="mb-0 d-inline-block bg-primary-4 rounded-3 shadow py-1 px-2">
                    <span className="text-gray-400">PIC :</span>{" "}
                    <i className="fa fa-fw fa-sm fa-user" /> {perusahaan.pic}
                  </p>
                </div>

                <div className="mt-2">
                  <span>
                    <i className="fa fa-fw fa-phone text-success-2 me-1" />{" "}
                    {perusahaan.no_telf ? (
                      perusahaan.no_telf
                    ) : (
                      <span className="text-gray-400">Tidak tersedia.</span>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <h6 className="text-black fw-semibold mb-3">
            Tahun Ajaran {form.tahun}
          </h6>

          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-lg-6">
                  <FormInput
                    label="No Surat"
                    name="no_surat"
                    value={form.no_surat}
                    error={errors.no_surat}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.value,
                        };
                      })
                    }
                  />
                </div>
                <div className="col-lg-6">
                  <FormInput
                    label="Perihal"
                    name="perihal"
                    value={form.perihal}
                    error={errors.perihal}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.value,
                        };
                      })
                    }
                  />
                </div>
                <div className="col-lg-6">
                  <FormInput
                    label="Ditujukan"
                    name="ditujukan"
                    value={form.ditujukan}
                    error={errors.ditujukan}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.value,
                        };
                      })
                    }
                  />
                </div>
                <div className="col-lg-6">
                  <FormInput
                    label="Tahun"
                    name="tahun"
                    value={form.tahun}
                    error={errors.tahun}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.value,
                        };
                      })
                    }
                    placeholder="example : 2021/2022"
                  />
                </div>
                <div className="col-lg-6">
                  <FormSelect
                    label="Jenjang"
                    name="jenjang"
                    value={form.jenjang}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.value,
                        };
                      })
                    }
                    error={errors.jenjang}
                  >
                    <option value="">Pilih</option>
                    <option value="D1">{getJenjang({ jenjang: "D1" })}</option>
                    <option value="D2">{getJenjang({ jenjang: "D2" })}</option>
                  </FormSelect>
                </div>
                <div className="col-lg-12 mb-3">
                  <div className="bg-light border border-silver rounded-3 p-3 mt-3">
                    <h6 className="text-center bg-primary rounded-3 text-white py-1">
                      2 Bulan OJT
                    </h6>
                    <div className="row">
                      <div className="col-lg-6">
                        <FormInput
                          label="Dari"
                          type="date"
                          name="dari_2"
                          value={form.dari_2}
                          error={errors.dari_2}
                          onChange={(e) =>
                            setForm((prevState) => {
                              return {
                                ...prevState,
                                [e.target.name]: e.target.value,
                              };
                            })
                          }
                        />
                      </div>
                      <div className="col-lg-6">
                        <FormInput
                          label="Sampai"
                          type="date"
                          name="sampai_2"
                          value={form.sampai_2}
                          error={errors.sampai_2}
                          onChange={(e) =>
                            setForm((prevState) => {
                              return {
                                ...prevState,
                                [e.target.name]: e.target.value,
                              };
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 mb-3">
                  <div className="bg-light border border-silver rounded-3 p-3 mt-3">
                    <h6 className="text-center bg-primary rounded-3 text-white py-1">
                      3 Bulan OJT
                    </h6>
                    <div className="row">
                      <div className="col-lg-6">
                        <FormInput
                          label="Dari"
                          type="date"
                          name="dari_4"
                          value={form.dari_4}
                          error={errors.dari_4}
                          onChange={(e) =>
                            setForm((prevState) => {
                              return {
                                ...prevState,
                                [e.target.name]: e.target.value,
                              };
                            })
                          }
                        />
                      </div>
                      <div className="col-lg-6">
                        <FormInput
                          label="Sampai"
                          type="date"
                          name="sampai_4"
                          value={form.sampai_4}
                          error={errors.sampai_4}
                          onChange={(e) =>
                            setForm((prevState) => {
                              return {
                                ...prevState,
                                [e.target.name]: e.target.value,
                              };
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 mb-3">
                  <div className="bg-light border border-silver rounded-3 p-3 mt-3">
                    <h6 className="text-center bg-primary rounded-3 text-white py-1">
                      6 Bulan OJT
                    </h6>
                    <div className="row">
                      <div className="col-lg-6">
                        <FormInput
                          label="Dari"
                          type="date"
                          name="dari_6"
                          value={form.dari_6}
                          error={errors.dari_6}
                          onChange={(e) =>
                            setForm((prevState) => {
                              return {
                                ...prevState,
                                [e.target.name]: e.target.value,
                              };
                            })
                          }
                        />
                      </div>
                      <div className="col-lg-6">
                        <FormInput
                          label="Sampai"
                          type="date"
                          name="sampai_6"
                          value={form.sampai_6}
                          error={errors.sampai_6}
                          onChange={(e) =>
                            setForm((prevState) => {
                              return {
                                ...prevState,
                                [e.target.name]: e.target.value,
                              };
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <FormTextarea
                    label="Keterangan"
                    name="penempatan"
                    value={form.penempatan}
                    error={errors.penempatan}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.value,
                        };
                      })
                    }
                    row={5}
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <JurusanSelect
                jurusan={jurusan}
                pojtJurusan={
                  pojt.pojt_jurusan &&
                  pojt.pojt_jurusan.length > 0 &&
                  pojt.pojt_jurusan
                }
                onSetJurusanSelect={(res) => setJurusanSelect(res)}
              />
            </div>
          </div>

          <div className="row mt-2">
            <NewSubmitButton
              submitText="Simpan Perubahaan"
              onClose={handleClose}
            />
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default PojtEdit;