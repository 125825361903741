import React, { useState } from "react";
import LayoutModal from "../../components/layouts/Modal";
import FormSelect from "../../components/forms/Select";
import FormInput from "../../components/forms/Input";
import { postPegawaiJabatan } from "../../api";
import { saAlert } from "../../helpers";

const PegawaiAddJabatan = ({ pegawai, dataJabatan, onFetchData }) => {
  const [modal, setModal] = useState(false);
  const [jabatan, setJabatan] = useState(pegawai.pegawai_jabatan || []);

  const [errors, setErrors] = useState({});

  const handleOpen = () => {
    setModal(true);
  };

  const handleClose = () => {
    setModal(false);
  };

  const handleChange = (e, index) => {
    setJabatan(
      jabatan.map((j, i) => {
        if (parseInt(i) === parseInt(index)) {
          if (e.target.name === "jabatan_id") {
            j.jabatan_id = e.target.value;
          }
          if (e.target.name === "tanggal_mulai") {
            j.tanggal_mulai = e.target.value;
          }
          if (e.target.name === "tanggal_berakhir") {
            j.tanggal_berakhir = e.target.value;
          }
        }
        return j;
      })
    );
  };

  let template = {
    pegawai_id: pegawai.id,
    jabatan_id: "",
    tanggal_mulai: "",
    tanggal_berakhir: "",
  };

  const handleAddJabatan = () => {
    setJabatan((prevState) => {
      return [...prevState, template];
    });
  };

  const handleRemoveJabatan = (index) => {
    setJabatan(jabatan.filter((j, i) => parseInt(i) !== parseInt(index)));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});

    try {
      const res = await postPegawaiJabatan({ jabatan });
      if (res.data && res.data.status === "success") {
        onFetchData();
        handleClose();
        saAlert("success", res.data.msg);
      }
    } catch (err) {
      err.response.status === 422 && setErrors(err.response.data.errors);
    }
  };

  return (
    <>
      <button
        className="btn btn-sm btn-primary-3 text-sm py-0 me-1"
        type="button"
        onClick={handleOpen}
      >
        <i className="fa fa-fw fa-plus text-sm"></i> Jabatan
      </button>
      <LayoutModal
        title={`Tambah jabatan untuk ${pegawai.nama}`}
        modal={modal}
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit}>
          <h5 className="fw-semibold text-black">
            Jabatan akan dianggap aktif oleh sistem ketika belum ditentukannya
            tanggal berakhir
          </h5>
          <p className="text-dark">
            Silahkan tekan tombol tambah jabatan untuk menampilkan kolom jabatan
          </p>
          <div className="mb-4 pb-1 px-4 border rounded-3">
            {jabatan.length > 0 &&
              jabatan.map((j, index) => {
                return (
                  <div className="d-flex align-items-center gap-2 p-2 my-3 border bg-silver-2 rounded-3" key={index}>
                    <div className="">
                      <FormSelect
                        label="Jabatan"
                        name="jabatan_id"
                        value={j.jabatan_id}
                        onChange={(e) => handleChange(e, index)}
                        error={errors.jabatan_id}
                      >
                        <option value="">Pilih</option>
                        {dataJabatan.length > 0 &&
                          dataJabatan.map((dj, index) => {
                            return (
                              <React.Fragment key={index}>
                                <option value={dj.id}>
                                  {dj.cabang && dj.cabang.nama} - {dj.nama}
                                </option>
                              </React.Fragment>
                            );
                          })}
                      </FormSelect>
                    </div>
                    <div className="">
                      <FormInput
                        type="date"
                        label="Tanggal Mulai"
                        name="tanggal_mulai"
                        value={j.tanggal_mulai}
                        onChange={(e) => handleChange(e, index)}
                        error={errors.tanggal_mulai}
                      />
                    </div>
                    <div className="">
                      <FormInput
                        type="date"
                        label="Tanggal Berakhir"
                        name="tanggal_berakhir"
                        value={j.tanggal_berakhir}
                        onChange={(e) => handleChange(e, index)}
                        error={errors.tanggal_berakhir}
                      />
                    </div>
                    <div className="d-flex justify-content-center align-items-end pt-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-danger-2 rounded-3 text-nowrap mt-1"
                        onClick={() => handleRemoveJabatan(index)}
                      >
                        <i className="fa fa-trash mr-1"></i>
                        Hapus
                      </button>
                    </div>
                  </div>
                );
              })}
            <button
              type="button"
              className="btn btn-primary-3 btn-sm mb-4 d-block text-primary"
              onClick={handleAddJabatan}
            >
              <i className="fa fa-plus mr-1"></i>
              Tambah Jabatan
            </button>
          </div>
          <div className="btn-group">
            <button className="btn btn-sm btn-primary-3 px-4" type="submit">
              Simpan
            </button>
            <button
              className="btn btn-sm btn-dark px-4"
              type="button"
              onClick={handleClose}
            >
              Kembali
            </button>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default PegawaiAddJabatan;
