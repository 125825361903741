import { useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { LayoutTable, LayoutThead } from "../components/layouts/Table";
import { getOrigin, isWindowView, saAlert } from "../helpers";
import { userState } from "../storage/auth";
import { titleState } from "../storage/pages";
import { GoogleGenerativeAI } from "@google/generative-ai";

const Dashboard = () => {
  const user = useRecoilValue(userState);
  const [title, setTitle] = useRecoilState(titleState);

  useEffect(() => {
    title === "No Title" &&
      saAlert("success", "Selamat datang!", user.nama);
    setTitle("Dashboard");
  }, [title, user.nama, setTitle]);





  const [myQuestion, setMyQuestion] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
    
  const API_KEY = "AIzaSyC7u7dZllzeQH90piFWfOGsNyTTCc0aLsQ";
  const genAI = new GoogleGenerativeAI(API_KEY);

  const [inputText, setInputText] = useState('');
  const [generatedText, setGeneratedText] = useState('');

  async function generateResponse() {
    // const model = genAI.getGenerativeModel({ model: "gemini-pro" });
    const model = genAI.getGenerativeModel({ model: "gemini-2.0-flash-lite" });
    setLoaded(true);
    try {
      const result = await model.generateContent(inputText);
      const response = await result.response;
      const text = await response.text();
      setLoaded(false);
  
      // Memisahkan teks menjadi baris-baris
      const lines = text.split('\n');
  
      // Memodifikasi teks untuk menampilkan teks tebal di dalam ** **
      const modifiedText = lines.map((line, index) => {
        const boldText = line.replace(/\*\*(.*?)\*\*/g, '<strong class="fw-semibold">$1</strong>');
        return (
          <span key={index}>
            {index !== 0 && <br />} {/* Menambahkan <br /> kecuali pada baris pertama */}
            <span dangerouslySetInnerHTML={{ __html: boldText }} />
          </span>
        );
      });
  
      setGeneratedText(modifiedText);
    } catch (error) {
      console.error('Error:', error);
      setGeneratedText('Error generating response');
    }
  }

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleGenerateClick = () => {
    generateResponse();
    setMyQuestion(inputText)
    setInputText('');
  };

  const isTypingTrue = () => {
    setIsTyping(true);
  }

  const isTypingFalse = () => {
    setIsTyping(false);
  }

  const textareaRef = useRef(null);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleGenerateClick();
      if (textareaRef.current) {
        textareaRef.current.blur();
      }
    }
  };

  return (
    <>








      <div className="row">
        {/* <div className="col-lg-6">
          <div className="border rounded-3 p-3" style={{backgroundColor: 'rgb(240, 240, 240)'}}>
            <h6 className="mb-0 fw-semibold text-primary"><i className="fa fa-fw fa-circle-exclamation"/> Informasi Penting!</h6>
            <p className="ps-3 text-sm">Dari : Kadek Lanang Lanusa Putera</p>
            <p className="mb-0 px-3">Saya sudah mendesain serta memperbaiki tampilan UI sistem di setiap halaman dari yang sering digunakan hingga yang terjarang, jika terdapat menu dengan tampilan UI yang masih rusak atau belum terdesain, mohon segera informasikan ke <span className="text-primary fw-semibold">Lanang (IT Programmer)</span>, sekian informasi dari saya, terimakasih.</p>
          </div>
        </div> */}
        <div className="col-lg-12">
          {!myQuestion ?
            <>
              <h2 className="fw-semibold text-primary">Alfa Artificial Intelligence</h2>
              <h6 className="fw-semibold text-success">Support by Google Gemini AI.</h6>
              <p className="">Sistem Alfa Prima kini hadir dengan fitur AI berbasis text, fitur ini memiliki kesamaan hampir menyerupai ChatGPT namun hanya saja ini berlaku untuk sebuah text saja, tidak support untuk gambar maupun kodingan.</p>
            </>
            :
            <>
              <div className="d-flex">
                <div>
                  <div className="d-inline-block bg-primary text-white text-sm rounded" style={{ padding: '2px 6px' }}>
                    <i className="fa fa-user" />
                  </div>
                </div>
                <div className="w-100 ps-2" style={{ paddingTop: '2px' }}>
                  <h6 className="fw-semibold">Anda</h6>
                  {!isTyping ? <p>{myQuestion}</p> : <p><i className="fa fa-fw fa-spin fa-spinner" /> Sedang mengetik. . .</p>}
                </div>
              </div>
              {/* <textarea
              placeholder="Masukkan teks di sini..."
              value={inputText}
              onChange={handleInputChange}
              rows={4}
              cols={50}
            ></textarea> */}
              {/* <button onClick={handleGenerateClick}>Generate Response</button> */}
              {/* <h2>Pertanyaan Anda:</h2> */}
              {/* <p>{myQuestion}</p> */}
              {/* <h2>Hasil:</h2> */}
              <div className="d-flex">
                <div>
                  <div className="d-inline-block bg-black text-warning text-sm rounded" style={{ padding: '2px 6px' }}>
                    <i className="fa fa-cog" />
                  </div>
                </div>
                <div className="w-100 ps-2" style={{ paddingTop: '2px' }}>
                  <h6 className="fw-semibold">Alfa Artificial Intelligence</h6>
                  {!loaded ?
                    <div>{generatedText}</div>
                    :
                    <span><i className="fa fa-fw fa-spin fa-spinner" /> Silahkan tunggu. . .</span>
                  }
                </div>
              </div>
            </>
          }
          <div className="d-flex justify-content-center align-items-center mt-3">
            <div className="input-group input-group-sm">
              <textarea
                ref={textareaRef}
                style={{ resize: 'none' }}
                className="form-control text-black form-control-custom rounded-start-3"
                placeholder="Masukkan teks di sini..."
                value={inputText}
                onChange={handleInputChange}
                onFocus={isTypingTrue}
                onBlur={isTypingFalse}
                onKeyDown={handleKeyDown}
                rows={2}
                cols={100}
              ></textarea>
              <button className="btn btn-primary-3 px-3 rounded-end-3" onClick={handleGenerateClick}><i className="fa fa-fw fa-paper-plane" /></button>
            </div>
          </div>
        </div>
      </div>
    {/* <div className="fixed-bottom mx-auto d-flex justify-content-center align-items-center my-lg-4 my-3 px-3 w-lg-50">
      <div className="input-group input-group-sm shadow-lg">
        <textarea
          ref={textareaRef}
          style={{resize: 'none'}}
          className="form-control text-black form-control-custom rounded-start-3"
          placeholder="Masukkan teks di sini..."
          value={inputText}
          onChange={handleInputChange}
          onFocus={isTypingTrue}
          onBlur={isTypingFalse}
          onKeyDown={handleKeyDown}
          rows={2}
          cols={100}
        ></textarea>
        <button className="btn btn-primary-3 px-3 rounded-end-3" onClick={handleGenerateClick}><i className="fa fa-fw fa-paper-plane"/></button>
      </div>
    </div> */}























          {/* <div className="row gy-3 my-0">
            <div className="col-lg-12">
              <div className="card rounded-3 shadow">
                {title &&
                  <div className="card-header border-0 bg-white shadow rounded-3 py-3">
                    <h6 className="m-0 fw-semibold text-primary">{title}</h6>
                    <p className="mb-0 text-secondary text-sm d-none d-sm-block">Gunakan <i className="fa fa-fw fa-keyboard" /> Shift + <i className="fa fa-fw fa-mouse" /> Mouse Scroll Up / Down untuk menggeser tabel kesamping. Pastikan <i className="fa fa-fw fa-mouse-pointer" /> kursor berada pada tabel yang ingin digeser.</p>
                  </div>
                }
                <div className="card-body">

                  <div>
                    <h6 className="mb-0 fw-semibold">Front End Update List :</h6>
                    <p className="mb-2 text-sm">Informasi ini hanya bersifat sementara selama proses update masih dilakukan.</p>

                    <div className="p-3 border rounded-3 mb-2">
                      <p className="mb-2">Rabu, 15 November 2023</p>
                      <ul className="mb-0">
                        <li className="fw-semibold">Memperbarui tampilan UI halaman checklist monitoring auditor</li>
                      </ul>
                    </div>

                    <div className="p-3 border rounded-3 mb-2">
                      <p className="mb-2">Senin, 13 November 2023</p>
                      <ul className="mb-0">
                        <li className="fw-semibold">memperbarui data jabatan dengan jabatan baru yang sudah ada relasinya</li>
                        <li className="fw-semibold">mengubah kondisi tombol tambah pegawai yang hanya bisa di klik oleh IT Programmer</li>
                        <li className="fw-semibold">Update tampilan UI halaman pendaftaran FO</li>
                      </ul>
                    </div>

                    <div className="p-3 border rounded-3 mb-2">
                      <p className="mb-2">Kamis, 9 November 2023</p>
                      <ul className="mb-0">
                        <li className="fw-semibold">Memperbaiki tampilan halaman kelas perkuliahan</li>
                        <li className="fw-semibold">Memperbaiki tampilan halaman pendaftaran FO</li>
                        <li className="fw-semibold">Memperbaiki tampilan halaman lembur</li>
                      </ul>
                    </div>

                    <div className="p-3 border rounded-3 mb-2">
                      <p className="mb-2">Rabu, 8 November 2023</p>
                      <ul className="mb-0">
                        <li className="fw-semibold">Memperbaiki tampilan soal penilaian 360</li>
                        <li className="fw-semibold">Memperbaiki tampilan penilaian kompetensi</li>
                      </ul>
                    </div>

                    <div className="p-3 border rounded-3 mb-2">
                      <p className="mb-2">Selasa, 7 November 2023</p>
                      <ul className="mb-0">
                        <li className="fw-semibold">edit tampilan halaman moU</li>
                        <li className="fw-semibold">menambahkan halaman baru</li>
                        <li className="fw-semibold">menghilangkan animasi budaya prima</li>
                        <li className="fw-semibold">Update tahun dari 2023 ke 2024 di print pendaftaran mahasiswa baru</li>
                        <li className="fw-semibold">Update tampilan login</li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div> */}







          {/* <div className="row">
            <div className="col-lg-12">
              <div className="mb-3">
                <label className="text-primary fw-semibold">Progress Perkembangan Pembaruan UI Sistem</label>
                <div className="progress" role="progressbar" aria-label="Example with label" aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>
                  <div className="progress-bar bg-gradient-info text-white" style={{width: '49%'}}>
                    <span className="fw-semibold">49%</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="city-background text-white rounded-3 d-flex flex-column justify-content-center align-items-center py-5 mb-3">
                <div className="py-5">
                  <h3 className="text-center fw-semibold text-warning">Selamat Datang!</h3>
                  <h6 className="text-center">{user.nama}</h6>
                </div>
              </div>

              <p className="fw-semibold fs-6 border-bottom pb-2 mb-2"><i className="fa fa-fw fa-up-long text-success" /> Bonus Manajemen</p>
              <ul className="mb-0">
                <li className="mb-2">Dengan mengucap puji syukur kehadapan Tuhan Yang Maha Esa dan rasa bangga kita kepada Alfa Prima..... Bonus Manajemen sudah ditransfer rekening ke masing masing.</li>
                <li className="mb-2">Pemberian Bonus Menejemen ini merupakan <span className="text-info">Kebijakan (Hadiah)</span> oleh Pimpinan Perusahaan. Besaran bonus manajemen diambil dari keuntungan masing-masing cabang dalam <span className="text-info">satu tahun</span> akademik.</li>
                <li className="mb-2">Perhitungan bonus untuk masing-masing orang ditentukan berdasarkan <span className="text-info">penilaian kinerja</span>, jabatan dan masa kerja efektif di tahun akademik lalu sejak diangkat <span className="text-info">tetap/kontrak</span>.</li>
              </ul>
            </div>

            <div className="col-lg-6">
              <div className="ratio ratio-16x9">
                <iframe
                  className="embed-responsive-item"
                  title="Slip Gaji"
                  src={`${getOrigin()}/storage/bonus_manajemen/${
                    user.nip
                  }.pdf`}
                  // src="/assets/img/sertifikasiProdi.pdf"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="mt-3">
                <p className="fw-semibold fs-6 border-bottom pb-2 mb-2"><i className="fa fa-fw fa-exclamation-triangle text-warning" /> Note</p>
                <ul className="mb-0">
                  <li className="mb-2">Sama halnya dengan Gaji, informasi Bonus bersifat <span className="text-info">rahasia</span>. Saling menunjukan atau dengan sengaja memperlihatkan informasi gaji, bonus dan tunjangan lain melanggar <span className="text-info">Basic Rules</span> Alfa Prima yang dapat dijatuhkan <span className="text-danger">sanksi</span>.</li>
                  <li className="mb-2">Slip Bonus dapat dilihat di SIM</li>
                </ul>
              </div>
            </div>
          </div> */}



































      {/* <div className="row">
        <div className="col-md-8 bg-light mb-5">
          <h1 className="text-primary text-center">Pengumuman</h1>
          <hr />
          <div className="row card-body">
            <div className="col-1">
              <h3>1</h3>
            </div>
            <div className="col-11">
              <h3>Bonus Manajemen</h3>
              <p>
                <span className="ml-5">
                  Dengan mengucap puji syukur kehadapan Tuhan Yang Maha Esa dan
                  rasa bangga kita kepada Alfa Prima..... Bonus Manajemen sudah
                  ditransfer rekening ke masing masing.
                </span>
                <br /> <br />
                <span className="ml-5">
                  Pemberian Bonus Menejemen ini merupakan{" "}
                  <b>Kebijakan (Hadiah)</b> oleh Pimpinan Perusahaan. Besaran
                  bonus manajemen diambil dari keuntungan masing-masing cabang
                  dalam satu tahun akademik.
                </span>
                <br /> <br />
                <span className="ml-5">
                  Perhitungan bonus untuk masing-masing orang ditentukan
                  berdasarkan penilaian kinerja, jabatan dan masa kerja efektif
                  di tahun akademik lalu sejak diangkat tetap/kontrak.
                </span>
                <br /> <br />
              </p>
              <b>NB</b>
              <ol>
                <li>
                  Sama halnya dengan Gaji, informasi Bonus bersifat rahasia.
                  Saling menunjukan atau dengan sengaja memperlihatkan informasi
                  gaji, bonus dan tunjangan lain melanggar Basic Rules Alfa
                  Prima yang dapat dijatuhkan sanksi.
                </li>
                <li>Slip Bonus dapat dilihat di SIM </li>
              </ol>
              {isWindowView() ? (
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    className="embed-responsive-item"
                    title="Slip Gaji"
                    src={`${getOrigin()}/storage/bonus_manajemen/${
                      user.nip
                    }.pdf`}
                    allowFullScreen
                  ></iframe>
                </div>
              ) : (
                <div className="embed-responsive embed-responsive-1by1">
                  <iframe
                    className="embed-responsive-item"
                    title="Slip Gaji"
                    src={`${getOrigin()}/storage/bonus_manajemen/${
                      user.nip
                    }.pdf`}
                    allowFullScreen
                  ></iframe>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-4"> */}
          {/* <h3 className="text-center">
            Penambahan / Update fitur yang sedang dilakukan
          </h3>
          <hr />
          <p className="text-warning">
            List fitur yang sedang di maintenance Programmer (Mungkin beberapa
            fitur pada menu ini belum bisa digunakan)
          </p>
          <div className="table-responsive">
            <LayoutTable>
              <LayoutThead>
                <th>Domain</th>
                <th>Menu</th>
                <th>Desc</th>
              </LayoutThead>
              <tbody className="text-primary">
                <tr>
                  <td>manajemen-alfaprima.com</td>
                  <td>All</td>
                  <td>Upgrade Version Framework</td>
                </tr>
                <tr>
                  <td>sim.alfaprima.id</td>
                  <td>Peminajaman Ruangan</td>
                  <td>Penambahan fitur baru</td>
                </tr>
                <tr>
                  <td>sia.alfaprima.id</td>
                  <td>Peminajaman Ruangan</td>
                  <td>Penambahan fitur baru</td>
                </tr>
                <tr>
                  <td>sim.alfaprima.id</td>
                  <td>Purchase Request</td>
                  <td>Penambahan Approval oleh Kacab</td>
                </tr>
                <tr>
                  <td>spada.alfaprima.id</td>
                  <td>All Menu</td>
                  <td>Testing</td>
                </tr>
              </tbody>
            </LayoutTable>
          </div>

          <h3>Status Sistem</h3>
          <div className="table-responsive">
            <LayoutTable>
              <LayoutThead>
                <th>Domain</th>
                <th>Desc</th>
                <th>Status Modifier</th>
                <th>Status</th>
              </LayoutThead>
              <tbody className="text-primary">
                <tr>
                  <td
                    type="button"
                    onClick={() =>
                      window.open("https://manajemen-alfaprima.com")
                    }
                  >
                    manajemen-alfaprima.com
                  </td>
                  <td>Service Backend</td>
                  <td>Seminggu terakhir</td>
                  <td className="text-primary">Maintenance</td>
                </tr>
                <tr>
                  <td
                    type="button"
                    onClick={() => window.open("https://alfaprima.id")}
                  >
                    alfaprima.id
                  </td>
                  <td>Company Profile</td>
                  <td>Sebulah terakhir</td>
                  <td className="text-success">Runing</td>
                </tr>
                <tr>
                  <td
                    type="button"
                    onClick={() =>
                      window.open("https://daftar.alfaprima.id/form")
                    }
                  >
                    daftar.alfaprima.id/form
                  </td>
                  <td>Pendaftaran kampus</td>
                  <td>Lebih dari setahun</td>
                  <td className="text-success">Runing</td>
                </tr>
                <tr>
                  <td
                    type="button"
                    onClick={() => window.open("https://sim.alfaprima.id")}
                  >
                    sim.alfaprima.id
                  </td>
                  <td>Administrasi Manajemen</td>
                  <td>Seminggu terakhir</td>
                  <td className="text-primary">Maintenance</td>
                </tr>
                <tr>
                  <td
                    type="button"
                    onClick={() => window.open("https://sia.alfaprima.id")}
                  >
                    sia.alfaprima.id
                  </td>
                  <td>Sistem Informasi untuk Mahasiswa</td>
                  <td>Seminggu terakhir</td>
                  <td className="text-primary">Maintenance</td>
                </tr>
                <tr>
                  <td
                    type="button"
                    onClick={() => window.open("https://sidos.alfaprima.id")}
                  >
                    sidos.alfaprima.id
                  </td>
                  <td>Sistem Informasi untuk Dosen</td>
                  <td>Sebulah terakhir</td>
                  <td className="text-success">Runing</td>
                </tr>
                <tr>
                  <td
                    type="button"
                    onClick={() => window.open("https://partner.alfaprima.id")}
                  >
                    partner.alfaprima.id
                  </td>
                  <td>Partner Alfa Prima</td>
                  <td>Seminggu terakhir</td>
                  <td className="text-success">Runing</td>
                </tr>
                <tr>
                  <td
                    type="button"
                    onClick={() => window.open("https://alumni.alfaprima.id")}
                  >
                    alumni.alfaprima.id
                  </td>
                  <td>Sistem Alumni</td>
                  <td>Lebih dari setahun</td>
                  <td className="text-success text-warning">Need Update</td>
                </tr>
                <tr>
                  <td
                    type="button"
                    onClick={() => window.open("https://spada.alfaprima.id")}
                  >
                    spada.alfaprima.id
                  </td>
                  <td>Sistem Pembelajaran</td>
                  <td>Sebulah terakhir</td>
                  <td className="text-success text-info">Testing</td>
                </tr>
              </tbody>
            </LayoutTable>
          </div> */}
        {/* </div>
      </div> */}

















      
    </>
  );
};

export default Dashboard;
