import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  fetchKpi,
  fetchPegawai,
  fetchingData,
  patchKpi,
  patchKpiAudit,
} from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  FilterCabang,
  FilterPagination,
  FilterSearch,
  FilterTahun,
} from "../../components/layouts/TopContent";
import PageNoData from "../../components/pages/NoData";
import SectionPagination from "../../components/sections/Pagination";
import { tahunAjaranState } from "../../storage/mhs";
import { titleState } from "../../storage/pages";
import { getCurrency, saAlert, saConfirm } from "../../helpers";
import LayoutModal from "../../components/layouts/Modal";
import { userState } from "../../storage/auth";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";

const KpiLaporan = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const user = useRecoilValue(userState);

  const [dataPegawai, setDataPegawai] = useState({});

  const tA = useRecoilValue(tahunAjaranState);

  const [page, setPage] = useState(1);
  const [cabang, setCabang] = useState("");
  const [tahun, setTahun] = useState(tA);
  const [pagination, setPagination] = useState("10");
  const [search, setSearch] = useState("");

  const setTitle = useSetRecoilState(titleState);

  useEffect(() => {
    setTitle("Data Pegawai");
  }, [setTitle]);

  const fetchData = useCallback(
    async (reset = true) => {
      if (reset) {
        setIsLoaded(false);
      }
      try {
        const res = await fetchPegawai(
          page,
          `search=${search}&cabang=${cabang}&tahun=${tahun}&pagination=${pagination}`
        );
        setDataPegawai(res.data.pegawai);
        setIsLoaded(true);
      } catch (err) {
        console.log(err);
      }
    },
    [page, cabang, tahun, pagination, search]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (user.is_auditor) {
    return (
      <>
        <div className="row mb-2">
          <div className="col-3">
            <FilterTahun
              value={tahun}
              onChange={(res) => {
                setTahun(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-3">
            <FilterCabang
              pusat={true}
              onChange={(res) => {
                setCabang(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-3">
            <FilterPagination
              onChange={(res) => {
                setPagination(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-3">
            <FilterSearch
              onChange={(res) => {
                setSearch(res);
                setPage(1);
              }}
            />
          </div>
        </div>
        {isLoaded && dataPegawai.data && dataPegawai.data.length > 0 ? (
          <TableNew>
            <Thead>
              <Tr className="text-center">
                <Th>No</Th>
                <Th>NIP</Th>
                <Th>Nama</Th>
                <Th>Cabang</Th>
                <Th>Jabatan</Th>
                <Th>Group</Th>
                <Th>Grade</Th>
              </Tr>
            </Thead>
            <Tbody>
              {dataPegawai.data.map((i, index) => {
                return (
                  <Tr key={index}>
                    <Td className="text-center">{dataPegawai.from + index}</Td>
                    <Td className="text-nowrap text-center">{i.nip}</Td>
                    <Td className="text-nowrap">{i.nama}</Td>
                    <Td className="text-center">{i.cabang}</Td>
                    <Td>
                      <AuditNow pegawai={i} tahun={tahun} />
                    </Td>
                    <Td className="text-center">{i.group}</Td>
                    <Td className="text-center">{i.grade}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </TableNew>
        ) : (
          <PageNoData />
        )}
        <SectionPagination
          links={dataPegawai.links}
          onChangePage={(res) => setPage(res)}
        />
      </>
    );
  }

  return (
    <h1>
      Tidak memiliki akses untuk mengaudit, hubungi HRD untuk detail lebih
      lanjut
    </h1>
  );
};

const AuditNow = ({ pegawai, tahun }) => {
  const [pegawaiJabatanAll, setPegawaiJabatanAll] = useState([]);

  useEffect(() => {
    setPegawaiJabatanAll(
      pegawai.pegawai_jabatan && pegawai.pegawai_jabatan.length > 0
        ? pegawai.pegawai_jabatan
            .filter((pj) => !pj.tanggal_berakhir)
            .map((pj) => pj)
        : []
    );
  }, [pegawai]);

  return (
    <>
      {pegawaiJabatanAll.length > 0 &&
        pegawaiJabatanAll.map((pj, index) => {
          return (
            <span
              type="button"
              key={index}
              className="btn btn-sm btn-primary-3 py-0 px-2 text-nowrap mr-2"
              onClick={() =>
                window.open(`/kpi-laporan/${pegawai.id}/${pj.jabatan_id}?tahun=${tahun}`)
              }
            >
              <i className="fa fa-file mr-1"></i>
              Laporan{` `}:{` `}
              {pj.jabatan && pj.jabatan.nama}
            </span>
          );
        })}
    </>
  );
};

export default KpiLaporan;
