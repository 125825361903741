import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  defaultFilterBulan,
  defaultFilterTahun,
  FilterBulan,
  FilterTahun,
} from "../../components/layouts/TopContent";
import { getBulan, getOrigin, isWindowView, namaBulan } from "../../helpers";
import { userState } from "../../storage/auth";
import { titleState } from "../../storage/pages";
import { showFileSlipGajiPegawai } from "../../api";

const SlipGaji = () => {
  const user = useRecoilValue(userState);
  const setTitle = useSetRecoilState(titleState);

  const [tahun, setTahun] = useState(defaultFilterTahun);
  const [bulan, setBulan] = useState(defaultFilterBulan);

  useEffect(
    () => setTitle(`Slip Gaji Bulan ${namaBulan[bulan - 1]} Tahun ${tahun}`),
    [setTitle, tahun, bulan]
  );

  return (
    <div>
      <div className="row">
        <div className="col-lg-6 mb-3">
          <FilterTahun onChange={(res) => setTahun(res)} />
        </div>
        <div className="col-lg-6 mb-3">
          <FilterBulan onChange={(res) => setBulan(res)} />
        </div>
      </div>
      {parseInt(tahun) === 2023 && parseInt(bulan) === 2 && (
        <div>
          <h3>Note :</h3>
          <p>
            Gaji bulan Februari 2023 sudah dilakukan penyesuian berdasarkan
            kinerja setiap karyawan. Besaran penyesuaian berdasarkan penilaian
            KPI (70%) dan Kompetensi 360 (30%). Sesuai dengan penilaian diatas.
          </p>

          <p className="m-0 p-0 font-weight-bold">NB :</p>
          <p>
            - Informasi Gaji bersifat rahasia. Saling menunjukan atau dengan
            sengaja memperlihatkan informasi gaji dan tunjangan lain melanggar
            Basic Rules Alfa Prima yang dapat dijatuhkan sanksi.
          </p>
        </div>
      )}
      <GajiView old={true} tahun={tahun} bulan={bulan} user={user}/>
    </div>
  );
};

const GajiView = ({ tahun, bulan, user}) => {
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState(false);
  const configMei2024 = parseInt(tahun) >= 2024 && parseInt(bulan) >= 5;

  // const fetchData = useCallback(async () => {
  //   if (configMei2024) {
  //     setLoading(true);
  //     try {
  //       const response = await showFileSlipGajiPegawai(tahun, bulan, user);
  //       if (response) {
  //         setUrl(true);
  //       } else {
  //         setUrl(false);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching slip gaji:', error);
  //       setUrl(false);
  //     } finally {
  //       setLoading(false);
  //     }
  //   }
  // }, [configMei2024, tahun, bulan, user]);
  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await showFileSlipGajiPegawai(tahun, bulan, user);
      if (response) {
        setUrl(true);
      } else {
        setUrl(false);
      }
    } catch (error) {
      console.error('Error fetching slip gaji:', error);
      setUrl(false);
    } finally {
      setLoading(false);
    }
  }, [tahun, bulan, user]);

  // useEffect(() => {
  //   if (configMei2024) {
  //     fetchData();
  //   } else {
  //     setLoading(false);
  //   }
  // }, [fetchData, configMei2024]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // const getUrl = () => {
  //   if (parseInt(tahun) >= 2023) {
  //     if (configMei2024) {
  //       return `${getOrigin()}/api/storage/slip_gaji/${tahun}/${bulan}/${user.nip}.pdf`;
  //     } else {
  //       return `${getOrigin()}/storage/slip_gaji/${tahun}/${bulan}/${user.nip}.pdf`;
  //     }
  //   } else {
  //     return `${getOrigin()}/storage/slip_gaji/${tahun}/${bulan}/${user.cabang}/${user.nip}.pdf`;
  //   }
  // };
  const getUrl = () => {
    return `${getOrigin()}/api/storage/slip_gaji/${tahun}/${bulan}/${user.nip}.pdf`;
  };

  return (
    url ? (
      isWindowView() ? (
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            className="embed-responsive-item"
            title="Slip Gaji"
            src={getUrl()}
            allowFullScreen
          ></iframe>
        </div>
      ) : (
        <div className="embed-responsive embed-responsive-1by1">
          <iframe
            className="embed-responsive-item"
            title="Slip Gaji"
            src={getUrl()}
            allowFullScreen
          ></iframe>
        </div>
      )
    ) : (
      <div className='d-flex justify-content-center align-items-center p-5 text-black rounded-3'>
        <div className='text-center'>
          <i className='fa fa-fw fa-circle-xmark text-danger' />
          <p className='mb-0'>Belum ada slip gaji pada bulan {getBulan(bulan)} {parseInt(tahun)}</p>
        </div>
      </div>
    )
  );
};

export default SlipGaji;