import axios from "../axios";
import { getOrigin, saAlert, saToast } from "../helpers";

export const fetchingData = async (
  icon = "success",
  title = "Loading, Mohon tunggu. . ."
) => {
  saToast(icon, title);
};

export const fetchDataControl = async (process) => {

  const route = `${window.location.origin}/slip-gaji`;

  try {
    let res = await process();
    return res;
  } catch (err) {
    if (parseInt(err.response.status) === 401) {
      saAlert("warning", "Silahkan refresh halaman kemudian lakukan login ulang.");
    } else if (parseInt(err.response.status) === 422) {
      throw err;
    } else {
      if (!window.location.href === route && !err.response.status === 404) {
        saAlert(
          "warning",
          String(err.response.status),
          err.response.data.message
        );
      }
    }
  }
};

// master
export const loginByThis = async (id) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post("/api/login-by-this", { id });
  });
};
export const resetPw = async (id) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post("/api/reset-pw", { id });
  });
};

// execute api
export const executeMaster = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post("/api/execute", data);
  });
};

// generate nilai
export const showToGenerateNilai = async () => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.get("/api/absensi-null");
  });
};
export const fetchToGenerateNilai = async (mhs_id, kelas_id) => {
  return await fetchDataControl(async () => {
    return await axios.get(
      `/api/set-nilai-absensi/${mhs_id}/false/${kelas_id}`
    );
  });
};
// end master

// Akademik
export const generateNewNim = async (id, data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/change-nim/${id}`, data);
  });
};
// End Akademik

// Pegawai
// index
export const fetchPegawai = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/pegawai?page=${page}&${custom}`);
  });
};
// show
export const showPegawai = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/pegawai/${id}?${custom}`);
  });
};
// store
export const postPegawai = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/pegawai`, data);
  });
};
// patch
export const patchPegawi = async (id, data, fd = false) => {
  fetchingData();

  let formData = new FormData();

  formData.append("_method", "PATCH");
  formData.append("grade", data.grade ? data.grade : null);
  formData.append("group", data.group ? data.group : null);
  formData.append("jabatan", data.jabatan ? data.jabatan : null);
  formData.append("cabang", data.cabang ? data.cabang : null);
  formData.append("atasan_1", data.atasan_1 ? data.atasan_1 : null);
  formData.append("atasan_2", data.atasan_2 ? data.atasan_2 : null);
  formData.append("nip", data.nip ? data.nip : null);
  formData.append("ktp", data.ktp ? data.ktp : null);
  formData.append("npwp", data.npwp ? data.npwp : null);
  formData.append("nama", data.nama ? data.nama : null);
  formData.append(
    "nama_panggilan",
    data.nama_panggilan ? data.nama_panggilan : null
  );
  formData.append("tempat_lahir", data.tempat_lahir ? data.tempat_lahir : null);
  formData.append(
    "tanggal_lahir",
    data.tanggal_lahir ? data.tanggal_lahir : null
  );
  formData.append("email", data.email ? data.email : null);
  formData.append("no_hp", data.no_hp ? data.no_hp : null);
  formData.append("agama", data.agama ? data.agama : null);
  formData.append("alamat", data.alamat ? data.alamat : null);
  formData.append("ttd", data.ttd ? data.ttd : null);

  return await fetchDataControl(async () => {
    return await axios.post(
      `/api/pegawai/${id}`,
      fd ? formData : { ...data, _method: "PATCH" }
    );
  });
};
// Delete
export const deletePegawai = async (id) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/pegawai/${id}`);
  });
};

// pendaftar
// Index
export const fetchPendaftar = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/pendaftar?page=${page}&${custom}`);
  });
};
// find
export const findPendaftar = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.get(`/api/pendaftar/${id}?${custom}`);
  });
};
// Patch
export const patchPendaftar = async (id, data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/pendaftar/${id}`, data);
  });
};
// Delete
export const deletePendaftar = async (id) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/pendaftar/${id}`);
  });
};
// renderNim
export const renderNim = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/pendaftar-render`, data);
  });
};

// pengajaran
// index
export const fetchPengajaran = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/pengajaran?page=${page}&${custom}`);
  });
};
// show
export const showPengajaran = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/pengajaran/${id}?${custom}`);
  });
};
// store
export const postPengajaran = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/pengajaran`, data);
  });
};
// update
export const patchPengajaran = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(
      `/api/pengajaran/${id}?${custom ? custom : ""}`,
      data
    );
  });
};
// delete
export const deletePengajaran = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/pengajaran/${id}&${custom}`);
  });
};

// afiliasi
// Index
export const fetchAfiliasi = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/afiliasi?page=${page}&${custom}`);
  });
};
// Update
export const patchAfiliasi = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(
      `/api/afiliasi/${id}?${custom ? custom : ""}`,
      data
    );
  });
};

// mhs
// index
export const fetchMhs = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/mhs?page=${page}&${custom}`);
  });
};
// show
export const findMhs = async (id, custom = "") => {
  // fetchingData();
  return await fetchDataControl(async () => {
    return await axios.get(`/api/mhs/${id}?${custom}`);
  });
};
// update
export const patchMhs = async (id, data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/mhs/${id}`, data);
  });
};

// alumni
// index
export const fetchAlumni = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/alumni?page=${page}&${custom}`);
  });
};

// dosen
// index
export const fetchDosen = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/dosen?page=${page}&${custom}`);
  });
};
// store
export const postDosen = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/dosen`, data);
  });
};
// show
export const showDosen = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/dosen/${id}?${custom}`);
  });
};
// Update
export const patchDosen = async (id, data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/dosen/${id}`, data);
  });
};
// delete
export const deleteDosen = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/dosen/${id}&${custom}`);
  });
};

// perusahaan
// index
export const fetchPerusahaan = async (page = 1, custom) => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/perusahaan?page=${page}&${custom}`);
  });
};
// store
export const postPerusahaan = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/perusahaan`, data);
  });
};
// update
export const patchPerusahaan = async (id, data) => {
  fetchingData();
  data.append('_method', 'PATCH');
  return await fetchDataControl(async () => {
    return await axios.post(`/api/perusahaan/${id}`, data);
  });
};
// delete
export const deletePerusahaan = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/perusahaan/${id}&${custom}`);
  });
};

// pojt
// index
export const fetchPojt = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/pojt?page=${page}&${custom}`);
  });
};
// show
export const showPojt = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/pojt/${id}?${custom}`);
  });
};
// store
export const postPojt = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/pojt`, data);
  });
};
// update
export const patchPojt = async (id, data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/pojt/${id}`, data);
  });
};
// delete
export const deletePojt = async (id) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/pojt/${id}`);
  });
};

// mhs_pojt
// POST
export const storeMhs_pojt = async (data = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/mhs_pojt`, data);
  });
};
// update
export const patchMhs_pojt = async (id, data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/mhs_pojt/${id}`, data);
  });
};
// Delete
export const deleteMhs_pojt = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/mhs_pojt/${id}?${custom}`);
  });
};

// Lembur
// index
export const fetchLembur = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/lembur?page=${page}&${custom}`);
  });
};
// create
export const postLembur = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/lembur`, data);
  });
};
// show
export const showLembur = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/lembur/show?page=${page}&${custom}`);
  });
};
// edit
export const patchLembur = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/lembur/${id}?${custom ? custom : ""}`, data);
  });
};
// delete
export const deleteLembur = async (id) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/lembur/${id}`);
  });
};

// Cuti
// create
export const postCuti = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/cuti`, data);
  });
};
// show
export const showCuti = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/cuti/show?page=${page}&${custom}`);
  });
};
// edit
export const patchCuti = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/cuti/${id}?${custom ? custom : ""}`, data);
  });
};
// delete
export const deleteCuti = async (id) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/cuti/${id}`);
  });
};

// jamkul
// index
export const fetchJamkul = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/jamkul?page=${page}&${custom}`);
  });
};
// store
export const postJamkul = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/jamkul`, data);
  });
};
// update
export const patchJamkul = async (id, data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/jamkul/${id}`, data);
  });
};

// kelompok matkul
export const fetchKelompokMatkul = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/kelompok-matkul?page=${page}&${custom}`);
  });
};
// store
export const postKelompokMatkul = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/kelompok-matkul`, data);
  });
};
// update
export const patchKelompokMatkul = async (id, data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/kelompok-matkul/${id}`, data);
  });
};

// matkul
// index
export const fetchMatkul = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/matkul?page=${page}&${custom}`);
  });
};
// store
export const postMatkul = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/matkul`, data);
  });
};
// update
export const patchMatkul = async (id, data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/matkul/${id}`, data);
  });
};

// Ruangan
// index
export const fetchRuangan = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/ruangan?page=${page}&${custom}`);
  });
};
// store
export const postRuangan = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/ruangan`, data);
  });
};
// update
export const patchRuangan = async (id, data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/ruangan/${id}`, data);
  });
};

// kelas
// index
export const fetchKelas = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/kelas?page=${page}&${custom}`);
  });
};
// show
export const showKelas = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/kelas/${id}?${custom}`);
  });
};
// store
export const postKelas = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/kelas`, data);
  });
};
// update
export const patchKelas = async (id, data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/kelas/${id}`, data);
  });
};

// jurusan
// index
export const fetchJurusan = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/jurusan?page=${page}&${custom}`);
  });
};

// krs
// show
export const showKrs = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/krs/${id}?${custom}`);
  });
};
// store
export const postKrs = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/krs`, data);
  });
};
// delete
export const deleteKrs = async (id) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/krs/${id}`);
  });
};

// Bidang
// index
export const fetchBidang = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/bidang?page=${page}&${custom}`);
  });
};
// show
export const showBidang = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/bidang/${id}?${custom}`);
  });
};
// store
export const postBidang = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/bidang`, data);
  });
};
// update
export const patchBidang = async (id, data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/bidang/${id}`, data);
  });
};

// Penyelenggara
// index
export const fetchPenyelenggara = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/penyelenggara?page=${page}&${custom}`);
  });
};
// show
export const showPenyelenggara = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/penyelenggara/${id}?${custom}`);
  });
};
// store
export const postPenyelenggara = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/penyelenggara`, data);
  });
};
// update
export const patchPenyelenggara = async (id, data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/penyelenggara/${id}`, data);
  });
};

// Progkam
// index
export const fetchProgkam = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/progkam?page=${page}&${custom}`);
  });
};
// show
export const showProgkam = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/progkam/${id}?${custom}`);
  });
};
// store
export const postProgkam = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/progkam`, data);
  });
};
// update
export const patchProgkam = async (id, data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/progkam/${id}`, data);
  });
};
// delete
export const deleteProgkam = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/progkam/${id}?${custom}`);
  });
};

// Absensi
// post
export const postAbsensi = async (data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/absensi?${custom}`, data);
  });
};
// delete
export const deleteAbsensi = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/absensi/${id}?${custom}`);
  });
};

// mhs progkam
// store
export const postMhsProgkam = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/mhs-progkam`, data);
  });
};
// update
export const patchMhsProgkam = async (id, data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/mhs-progkam/${id}`, data);
  });
};
// Delete
export const deleteMhsProgkam = async (id) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/mhs-progkam/${id}`);
  });
};

// disc
// store
export const postDisc = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/disc`, data);
  });
};

// biaya
// store
export const postBiaya = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/biaya`, data);
  });
};

// angsuran
// store
export const postAngsuran = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/angsuran`, data);
  });
};
// show
export const showAngsuran = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/angsuran/${id}?${custom}`);
  });
};

// penilaian dosen
// index
export const fetchPenilaianDosen = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/penilaian-dosen?page=${page}&${custom}`);
  });
};

// Ks
// index
export const fetchKs = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/ks?page=${page}&${custom}`);
  });
};
// post
export const postKs = async (data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/ks?${custom}`, data);
  });
};

// Buku Perpustakaan
// index
export const fetchBukuPerpustakaan = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/buku-perpustakaan?page=${page}&${custom}`);
  });
};
// store
export const postBukuPerpustakaan = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/buku-perpustakaan`, data);
  });
};
// update
export const patchBukuPerpustakaan = async (id, data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/buku-perpustakaan/${id}`, data);
  });
};
// delete
export const deleteBukuPerpustakaan = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/buku-perpustakaan/${id}?${custom}`);
  });
};

// Krs
// index
export const fetchKrs = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/krs?page=${page}&${custom}`);
  });
};

// nilai ojt
export const fetchNilaiOjt = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/nilai-ojt?page=${page}&${custom}`);
  });
};
// store
export const postNilaiOjt = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/nilai-ojt`, data);
  });
};
// update
export const patchNilaiOjt = async (id, data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/nilai-ojt/${id}`, data);
  });
};
// delete
export const deleteNilaiOjt = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/nilai-ojt/${id}?${custom}`);
  });
};

// nilai
export const fetchNilai = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/nilai?page=${page}&${custom}`);
  });
};
// store
export const postNilai = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/nilai`, data);
  });
};
// update
export const patchNilai = async (id, data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/nilai/${id}`, data);
  });
};
// delete
export const deleteNilai = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/nilai/${id}?${custom}`);
  });
};

// pendaftar_intership
// index
export const fetchPendaftarIntership = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/pendaftar-intership?page=${page}&${custom}`);
  });
};
// show
export const showPendaftarIntership = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/pendaftar-intership/${id}?${custom}`);
  });
};
// store
export const postPendaftarIntership = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/pendaftar-intership`, data);
  });
};
// update
export const patchPendaftarIntership = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(
      `/api/pendaftar-intership/${id}?${custom ? custom : ""}`,
      data
    );
  });
};
// delete
export const deletePendaftarIntership = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/pendaftar-intership/${id}&${custom}`);
  });
};

// NilaiAkhir
// index
export const fetchNilaiAkhir = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/nilai-akhir?page=${page}&${custom}`);
  });
};
// show
export const showNilaiAkhir = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/nilai-akhir/${id}?${custom}`);
  });
};
// store
export const postNilaiAkhir = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/nilai-akhir`, data);
  });
};
// update
export const patchNilaiAkhir = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(
      `/api/nilai-akhir/${id}?${custom ? custom : ""}`,
      data
    );
  });
};
// delete
export const deleteNilaiAkhir = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/nilai-akhir/${id}&${custom}`);
  });
};

// LinkSurvei
// index
export const fetchLinkSurvei = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/link-survei?page=${page}&${custom}`);
  });
};
// show
export const showLinkSurvei = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/link-survei/${id}?${custom}`);
  });
};
// store
export const postLinkSurvei = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/link-survei`, data);
  });
};
// update
export const patchLinkSurvei = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(
      `/api/link-survei/${id}?${custom ? custom : ""}`,
      data
    );
  });
};
// delete
export const deleteLinkSurvei = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/link-survei/${id}&${custom}`);
  });
};

// LinkSop
// index
export const fetchLinkSop = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/link-sop?page=${page}&${custom}`);
  });
};
// show
export const showLinkSop = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/link-sop/${id}?${custom}`);
  });
};
// store
export const postLinkSop = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/link-sop`, data);
  });
};
// update
export const patchLinkSop = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(
      `/api/link-sop/${id}?${custom ? custom : ""}`,
      data
    );
  });
};
// delete
export const deleteLinkSop = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/link-sop/${id}&${custom}`);
  });
};

// Sk Dosen
// index
export const fetchSkDosen = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/sk-dosen?page=${page}&${custom}`);
  });
};
// show
export const showSkDosen = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/sk-dosen/${id}?${custom}`);
  });
};
// store
export const postSkDosen = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/sk-dosen`, data);
  });
};
// update
export const patchSkDosen = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(
      `/api/sk-dosen/${id}?${custom ? custom : ""}`,
      data
    );
  });
};
// delete
export const deleteSkDosen = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/sk-dosen/${id}&${custom}`);
  });
};

// DailyReport
// index
export const fetchDailyReport = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/daily-report?page=${page}&${custom}`);
  });
};
// show
export const showDailyReport = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/daily-report/${id}?${custom}`);
  });
};
// store
export const postDailyReport = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/daily-report`, data);
  });
};
// update
export const patchDailyReport = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(
      `/api/daily-report/${id}?${custom ? custom : ""}`,
      data
    );
  });
};
// delete
export const deleteDailyReport = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/daily-report/${id}&${custom}`);
  });
};

// Ijin
// index
export const fetchIjin = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/ijin?page=${page}&${custom}`);
  });
};
// show
export const showIjin = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/ijin/${id}?${custom}`);
  });
};
// store
export const postIjin = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/ijin`, data);
  });
};
// update
export const patchIjin = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/ijin/${id}?${custom ? custom : ""}`, data);
  });
};
// delete
export const deleteIjin = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/ijin/${id}&${custom}`);
  });
};

// Meninggal Tugas
// index
export const fetchMeninggalkanTugas = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/meninggalkan-tugas?page=${page}&${custom}`);
  });
};
// show
export const showMeninggalkanTugas = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/meninggalkan-tugas/${id}?${custom}`);
  });
};
// store
export const postMeninggalkanTugas = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/meninggalkan-tugas`, data);
  });
};
// update
export const patchMeninggalkanTugas = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(
      `/api/meninggalkan-tugas/${id}?${custom ? custom : ""}`,
      data
    );
  });
};
// delete
export const deleteMeninggalkanTugas = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/meninggalkan-tugas/${id}&${custom}`);
  });
};

// Buku Saku
// index
export const fetchBukuSaku = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/buku-saku?page=${page}&${custom}`);
  });
};
// show
export const showBukuSaku = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/buku-saku/${id}?${custom}`);
  });
};
// store
export const postBukuSaku = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/buku-saku`, data);
  });
};
// update
export const patchBukuSaku = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(
      `/api/buku-saku/${id}?${custom ? custom : ""}`,
      data
    );
  });
};
// delete
export const deleteBukuSaku = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/buku-saku/${id}&${custom}`);
  });
};

// Buku Saku Kegiatan
// index
export const fetchBukuSakuKegiatan = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/buku-saku-kegiatan?page=${page}&${custom}`);
  });
};
// show
export const showBukuSakuKegiatan = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/buku-saku-kegiatan/${id}?${custom}`);
  });
};
// store
export const postBukuSakuKegiatan = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/buku-saku-kegiatan`, data);
  });
};
// update
export const patchBukuSakuKegiatan = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(
      `/api/buku-saku-kegiatan/${id}?${custom ? custom : ""}`,
      data
    );
  });
};
// delete
export const deleteBukuSakuKegiatan = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/buku-saku-kegiatan/${id}&${custom}`);
  });
};

// Wisuda
// index
export const fetchWisuda = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/wisuda?page=${page}&${custom}`);
  });
};
// show
export const showWisuda = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/wisuda/${id}?${custom}`);
  });
};
// store
export const postWisuda = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/wisuda`, data);
  });
};
// update
export const patchWisuda = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/wisuda/${id}?${custom ? custom : ""}`, data);
  });
};
// delete
export const deleteWisuda = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/wisuda/${id}&${custom}`);
  });
};

// Peminjaman Ruangan
// index
export const fetchPeminjamanRuangan = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/peminjaman-ruangan?page=${page}&${custom}`);
  });
};
// show
export const showPeminjamanRuangan = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/peminjaman-ruangan/${id}?${custom}`);
  });
};
// store
export const postPeminjamanRuangan = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/peminjaman-ruangan`, data);
  });
};
// update
export const patchPeminjamanRuangan = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(
      `/api/peminjaman-ruangan/${id}?${custom ? custom : ""}`,
      data
    );
  });
};
// delete
export const deletePeminjamanRuangan = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/peminjaman-ruangan/${id}&${custom}`);
  });
};

// Jabatan
// index
export const fetchJabatan = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/jabatan?page=${page}&${custom}`);
  });
};
// show
export const showJabatan = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/jabatan/${id}?${custom}`);
  });
};
// store
export const postJabatan = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/jabatan`, data);
  });
};
// update
export const patchJabatan = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(
      `/api/jabatan/${id}?${custom ? custom : ""}`,
      data
    );
  });
};
// delete
export const deleteJabatan = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/jabatan/${id}&${custom}`);
  });
};

// Kpi
// index
export const fetchKpi = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/kpi?page=${page}&${custom}`);
  });
};
// show
export const showKpi = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/kpi/${id}?${custom}`);
  });
};
// store
export const postKpi = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/kpi`, data);
  });
};
// update
export const patchKpi = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(
      `/api/kpi/${id}?${custom ? custom : ""}`,
      data
    );
  });
};
// delete
export const deleteKpi = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/kpi/${id}&${custom}`);
  });
};

// Absensi Pegawai
// index
export const fetchAbsensiPegawai = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/absensi-pegawai?page=${page}&${custom}`);
  });
};
// show
export const showAbsensiPegawai = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/absensi-pegawai/${id}?${custom}`);
  });
};
// store
export const postAbsensiPegawai = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/absensi-pegawai`, data);
  });
};
// update
export const patchAbsensiPegawai = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/absensi-pegawai/${id}?${custom ? custom : ""}`, data);
  });
};
// delete
export const deleteAbsensiPegawai = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/absensi-pegawai/${id}&${custom}`);
  });
};

// Morning Report
// index
export const fetchMorningReport = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/morning-report?page=${page}&${custom}`);
  });
};
// show
export const showMorningReport = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/morning-report/${id}?${custom}`);
  });
};
// store
export const postMorningReport = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/morning-report`, data);
  });
};
// update
export const patchMorningReport = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/morning-report/${id}?${custom ? custom : ""}`, data);
  });
};
// delete
export const deleteMorningReport = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/morning-report/${id}&${custom}`);
  });
};

// Morning Report List
// index
export const fetchMorningReportList = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/morning-report-list?page=${page}&${custom}`);
  });
};
// show
export const showMorningReportList = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/morning-report-list/${id}?${custom}`);
  });
};
// store
export const postMorningReportList = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/morning-report-list`, data);
  });
};
// update
export const patchMorningReportList = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/morning-report-list/${id}?${custom ? custom : ""}`, data);
  });
};
// delete
export const deleteMorningReportList = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/morning-report-list/${id}&${custom}`);
  });
};

// Pegawai Jabatan
// index
export const fetchPegawaiJabatan = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/pegawai-jabatan?page=${page}&${custom}`);
  });
};
// show
export const showPegawaiJabatan = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/pegawai-jabatan/${id}?${custom}`);
  });
};
// store
export const postPegawaiJabatan = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/pegawai-jabatan`, data);
  });
};
// update
export const patchPegawaiJabatan = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/pegawai-jabatan/${id}?${custom ? custom : ""}`, data);
  });
};
// delete
export const deletePegawaiJabatan = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/pegawai-jabatan/${id}&${custom}`);
  });
};

// Pengumuman
// index
export const fetchPengumuman = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/pengumuman?page=${page}&${custom}`);
  });
};
// show
export const showPengumuman = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/pengumuman/${id}?${custom}`);
  });
};
// store
export const postPengumuman = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/pengumuman`, data);
  });
};
// update
export const patchPengumuman = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/pengumuman?pengumuman_id=${id}&${custom ? custom : ""}`, data)
  });
};
// delete
export const deletePengumuman = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/pengumuman/${id}&${custom}`);
  });
};

// // Departemen
// // index
// export const fetchDepartemen = async (page = 1, custom = "") => {
//   return await fetchDataControl(async () => {
//     return await axios.get(`/api/departemen?page=${page}&${custom}`);
//   });
// };
// // show
// export const showDepartemen = async (id, custom = "") => {
//   return await fetchDataControl(async () => {
//     return await axios.get(`/api/departemen/${id}?${custom}`);
//   });
// };
// // store
// export const postDepartemen = async (data) => {
//   fetchingData();
//   return await fetchDataControl(async () => {
//     return await axios.post(`/api/departemen`, data);
//   });
// };
// // update
// export const patchDepartemen = async (id, data, custom = "") => {
//   fetchingData();
//   return await fetchDataControl(async () => {
//     return await axios.patch(`/api/departemen/${id}?${custom ? custom : ""}`, data);
//   });
// };
// // delete
// export const deleteDepartemen = async (id, custom = "") => {
//   fetchingData();
//   return await fetchDataControl(async () => {
//     return await axios.delete(`/api/departemen/${id}&${custom}`);
//   });
// };

// AspekBsc
// index
export const fetchAspekBsc = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/aspek-bsc?page=${page}&${custom}`);
  });
};
// show
export const showAspekBsc = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/aspek-bsc/${id}?${custom}`);
  });
};
// store
export const postAspekBsc = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/aspek-bsc`, data);
  });
};
// update
export const patchAspekBsc = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/aspek-bsc/${id}?${custom ? custom : ""}`, data);
  });
};
// delete
export const deleteAspekBsc = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/aspek-bsc/${id}&${custom}`);
  });
}; 

// Kpi Audit
// index
export const fetchKpiAudit = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/kpi-audit?page=${page}&${custom}`);
  });
};
// show
export const showKpiAudit = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/kpi-audit/${id}?${custom}`);
  });
};
// store
export const postKpiAudit = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/kpi-audit`, data);
  });
};
// update
export const patchKpiAudit = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/kpi-audit/${id}?${custom ? custom : ""}`, data);
  });
};
// delete
export const deleteKpiAudit = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/kpi-audit/${id}&${custom}`);
  });
}; 

// RKAT
// index
export const fetchRkat = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/rkat?page=${page}&${custom}`);
  });
};
// store
export const postRkat = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/rkat`, data);
  });
};
// update
export const patchRkat = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/rkat/${id}?${custom ? custom : ""}`, data);
  });
};
// delete
export const deleteRkat = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/rkat/${id}&${custom}`);
  });
};

// RKAT LIST
// index
export const fetchRkatList = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/rkat-list?page=${page}&${custom}`);
  });
};
// store
export const postRkatList = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/rkat-list`, data);
  });
};
// update
export const patchRkatList = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/rkat-list/${id}?${custom ? custom : ""}`, data);
  });
};
// delete
export const deleteRkatList = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/rkat-list/${id}&${custom}`);
  });
};

// Direktorat
// index
export const fetchDirektorat = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/direktorat?page=${page}&${custom}`);
  });
};
// show
export const showDirektorat = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/direktorat/${id}?${custom}`);
  });
};
// store
export const postDirektorat = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/direktorat`, data);
  });
};
// update
export const patchDirektorat = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/direktorat/${id}?${custom ? custom : ""}`, data);
  });
};
// delete
export const deleteDirektorat = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/direktorat/${id}&${custom}`);
  });
};

// Divisi
// index
export const fetchDivisi = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/divisi?page=${page}&${custom}`);
  });
};
// show
export const showDivisi = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/divisi/${id}?${custom}`);
  });
};
// store
export const postDivisi = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/divisi`, data);
  });
};
// update
export const patchDivisi = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/divisi/${id}?${custom ? custom : ""}`, data);
  });
};
// delete
export const deleteDivisi = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/divisi/${id}&${custom}`);
  });
};


// Departemen
// index
export const fetchDepartemen = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/departemen?page=${page}&${custom}`);
  });
};
// show
export const showDepartemen = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/departemen/${id}?${custom}`);
  });
};
// store
export const postDepartemen = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/departemen`, data);
  });
};
// update
export const patchDepartemen = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/departemen/${id}?${custom ? custom : ""}`, data);
  });
};
// delete
export const deleteDepartemen = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/departemen/${id}&${custom}`);
  });
};

// Pelaksana
// index
export const fetchPelaksana = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/pelaksana?page=${page}&${custom}`);
  });
};
// show
export const showPelaksana = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/pelaksana/${id}?${custom}`);
  });
};
// store
export const postPelaksana = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/pelaksana`, data);
  });
};
// update
export const patchPelaksana = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/pelaksana/${id}?${custom ? custom : ""}`, data);
  });
};
// delete
export const deletePelaksana = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/pelaksana/${id}&${custom}`);
  });
};

export const getPrivateFile = async (par) => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/storage/${par}`);
  });
};

// arus kas
// index
export const fetchArusKas = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/arus-kas?page=${page}&${custom}`);
  });
};
// store
export const postArusKas = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/arus-kas`, data);
  });
};
// update
export const patchArusKas = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/arus-kas/${id}?${custom ? custom : ""}`, data);
  });
};
// delete
export const deleteArusKas = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/arus-kas/${id}&${custom}`);
  });
};

// arus kas list
// index
export const fetchArusKasList = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/arus-kas-list?page=${page}&${custom}`);
  });
};
// store
export const postArusKasList = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/arus-kas-list`, data);
  });
};
// update
export const patchArusKasList = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/arus-kas-list/${id}?${custom ? custom : ""}`, data);
  });
};
// delete
export const deleteArusKasList = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/arus-kas-list/${id}&${custom}`);
  });
};

// berita terkini
// index
export const fetchBeritaTerkini = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/berita-terkini?page=${page}&${custom}`);
  });
};
// store
export const postBeritaTerkini = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/berita-terkini`, data);
  });
};
// update
export const patchBeritaTerkini = async (id, data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/berita-terkini/${id}`, data);
  });
};
// delete
export const deleteBeritaTerkini = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/berita-terkini/${id}&${custom}`);
  });
};

// file honor dosen
// store
export const postFileSlipGaji = async (data, loaded) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/dosen-slip`, data, loaded);
  });
};

// file angket dosen
// store
export const postFileAngket = async (data, loaded) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/dosen-angket`, data, loaded);
  });
};

// file hasil monitoring dosen
// store
export const postFileHasilMonitoring = async (data, loaded) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/dosen-hasil-monitoring`, data, loaded);
  });
};

// file slip gaji pegawai
// store
export const postFileSlipGajiPegawai = async (data, loaded) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/slip-gaji-pegawai`, data, loaded);
  });
};
// file upload jobdesk
// store
export const postUploadJobdesk = async (data, loaded) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/job-desk-pegawai`, data, loaded);
  });
};
// show
export const showFileSlipGajiPegawai = async (tahun, bulan, user) => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/storage/slip_gaji/${tahun}/${bulan}/${user.nip}.pdf`);
  });
};

// perusahaan alumni
// index
export const fetchPerusahaanAlumni = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/perusahaan-alumni?page=${page}&${custom}`);
  });
};
// store
export const postPerusahaanAlumni = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/perusahaan-alumni`, data);
  });
};
// update
export const patchPerusahaanAlumni = async (id, data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/perusahaan-alumni/${id}`, data);
  });
};
// delete
export const deletePerusahaanAlumni = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/perusahaan-alumni/${id}&${custom}`);
  });
};

// survey mahasiswa
// index
export const fetchSurveyMhs = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/survey?page=${page}&${custom}`);
  });
};
// store
export const postSurveyMhs = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/survey`, data);
  });
};
// update
export const patchSurveyMhs = async (id, data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/survey/${id}`, data);
  });
};
// delete
export const deleteSurveyMhs = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/survey/${id}&${custom}`);
  });
};

// jenis survey mahasiswa
// index
export const fetchJenisSurveyMhs = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/jenis-survey?page=${page}&${custom}`);
  });
};
// store
export const postJenisSurveyMhs = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/jenis-survey`, data);
  });
};
// update
export const patchJenisSurveyMhs = async (id, data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/jenis-survey/${id}`, data);
  });
};
// delete
export const deleteJenisSurveyMhs = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/jenis-survey/${id}&${custom}`);
  });
};

// soal survey mahasiswa
// index
export const fetchSoalSurveyMhs = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/soal-survey?page=${page}&${custom}`);
  });
};
// store
export const postSoalSurveyMhs = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/soal-survey`, data);
  });
};
// update
export const patchSoalSurveyMhs = async (id, data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/soal-survey/${id}`, data);
  });
};
// delete
export const deleteSoalSurveyMhs = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/soal-survey/${id}&${custom}`);
  });
};

// tutorial
// index
export const fetchTutorial = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/tutorial?page=${page}&${custom}`);
  });
};
// show
export const showTutorial = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/tutorial/${id}?${custom}`);
  });
};
// store
export const postTutorial = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/tutorial`, data);
  });
};
// update
export const patchTutorial = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/tutorial/${id}?${custom ? custom : ""}`, data);
  });
};
// delete
export const deleteTutorial = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/tutorial/${id}&${custom}`);
  });
};

// brosur digital
// index
export const fetchBrosurDigital = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/brosur?page=${page}&${custom}`);
  });
};
// show
export const showBrosurDigital = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/brosur/${id}?${custom}`);
  });
};
// store
export const postBrosurDigital = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/brosur`, data);
  });
};
// update
export const patchBrosurDigital = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/brosur/${id}?${custom ? custom : ""}`, data);
  });
};
// delete
export const deleteBrosurDigital = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/brosur/${id}&${custom}`);
  });
};

// rencana pengajaran
// index
export const fetchRencanaPengajaran = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/rencana-pengajaran?page=${page}&${custom}`);
  });
};
// show
export const showRencanaPengajaran = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/rencana-pengajaran/${id}?${custom}`);
  });
};
// store
export const postRencanaPengajaran = async (data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/rencana-pengajaran?${custom}`, data);
  });
};
// update
export const patchRencanaPengajaran = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/rencana-pengajaran/${id}?${custom ? custom : ""}`, data);
  });
};
// delete
export const deleteRencanaPengajaran = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/rencana-pengajaran/${id}&${custom}`);
  });
};