import React, { useCallback, useEffect, useRef, useState } from 'react'
import { LayoutAddBtn } from '../../components/layouts/TopContent';
import LayoutModal, { NewSubmitButton } from '../../components/layouts/Modal';
import FormSelect from '../../components/forms/Select';
import { namaBulan, saAlert, saConfirm } from '../../helpers';
import { useDropzone } from 'react-dropzone';
import SmallButton from '../../components/forms/SmallButton';
import { postFileSlipGaji, postFileSlipGajiPegawai, postUploadJobdesk } from '../../api';

function UploadJobdesk({ onFetchData }) {

    // shortcut
    const maximumSize = 100; // MB
    const maximumFile = 100; // max total file untuk di submit
    const uploadName = 'Upload Jobdesk';

    const [modal, setModal] = useState(false);
    const [form, setForm] = useState({
        tahun: '',
        files: []
    }); // state field siap submit
    const [errors, setErrors] = useState({});
    const [files, setFiles] = useState([]); // state antrean file
    const [filesError, setFilesError] = useState([]); // state file yang gagal / error
    const [totalSize, setTotalSize] = useState(0); // state total size antrean
    const [fileUploadProgress, setFileUploadProgress] = useState({}); // state progress saat submit
    const [progress, setProgress] = useState(false);
    const tagRef = useRef(null);

    const handleOpen = () => {
        setModal(true);
    };

    const handleClose = () => {
        tagRef?.current?.reset();
        setForm({
            tahun: '',
            files: []
        });
        setErrors({});
        setFiles([]);
        setFilesError([])
        setTotalSize(0);
        setFileUploadProgress({});
        setProgress(false);
        setModal(false);
    };

    const handleChange = (e) => {
        setForm((prevState) => {
            return { ...prevState, [e.target.name]: e.target.value };
        });
    }

    function bytesToSize(bytes) {
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }

    const calculateTotalSize = files => {
        return files.reduce((total, file) => total + file.size, 0);
    };

    const onDrop = useCallback(acceptedFiles => {
        const uniqueFiles = acceptedFiles.filter(file => !files.some(existingFile => existingFile.name === file.name));
        const duplicateFiles = acceptedFiles.filter(file => files.some(existingFile => existingFile.name === file.name));
        if (duplicateFiles.length > 0) {
            const duplicateFileNames = duplicateFiles.map(file => file.name).join(', ');
            saAlert("error", "Beberapa file sudah ada di dalam antrean.", `File yang terdeteksi: ${duplicateFileNames}`);
        }
        const totalFileSize = calculateTotalSize(uniqueFiles);

        setFiles(prevFiles => [...prevFiles, ...uniqueFiles]);
        setTotalSize(prevTotalSize => prevTotalSize + totalFileSize);
        setForm(prevForm => ({
            ...prevForm,
            files: [...prevForm.files, ...uniqueFiles]
        }));
    }, [files, totalSize]);

    const handleDeleteFile = fileToDelete => {
        setTotalSize(prevTotalSize => prevTotalSize - fileToDelete.size);
        setFiles(prevFiles => prevFiles.filter(file => file !== fileToDelete));
        setForm(prevForm => ({
            ...prevForm,
            files: prevForm.files.filter(file => file !== fileToDelete)
        }));
    };

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: { 'application/pdf': ['.pdf'] }
    });

    const previewFile = files.map((file, index) => (
        <React.Fragment key={file.path}>
            <tr>
                <td className='align-middle text-center border-silver px-3'>{index + 1}</td>
                <td className='align-middle border-silver px-3'>{file.path}</td>
                <td className='align-middle text-center text-nowrap border-silver px-3'>{bytesToSize(file.size)}</td>
                <td className='align-middle text-center text-nowrap border-silver px-3'><SmallButton onClick={() => handleDeleteFile(file)} type="delete" /></td>
            </tr>
            <tr>
                <td colSpan={5} className='p-0 m-0 align-middle text-center border-silver'>
                    <div
                        className={`${progress ? 'd-block' : 'd-none'}`}
                    >
                        <div className={`progress bg-silver rounded-0`} style={{ height: '24px', boxShadow: 'inset 0px 0px 10px rgb(0, 0, 0, 0.15)' }} role="progressbar" aria-label="Progress bar" aria-valuenow={fileUploadProgress[file.name] || 0} aria-valuemin={0} aria-valuemax={100}>
                            <div
                                className={`progress-bar
                                    ${parseInt(fileUploadProgress[file.name]) >= 70 && parseInt(fileUploadProgress[file.name]) <= 100 && 'bg-gradient-success'}
                                    ${parseInt(fileUploadProgress[file.name]) >= 50 && parseInt(fileUploadProgress[file.name]) < 70 && 'bg-gradient-warning'}
                                    ${parseInt(fileUploadProgress[file.name]) >= 0 && parseInt(fileUploadProgress[file.name]) < 50 && 'bg-gradient-danger'}
                                `}
                                style={{ width: `${parseInt(fileUploadProgress[file.name]) || 0}%` }}
                            >
                                {parseInt(fileUploadProgress[file.name]) === 100 ? 'Selesai' : parseInt(fileUploadProgress[file.name]) + "%" || 0}
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </React.Fragment>
    ));

    const handleSubmit = async (e) => {
        e.preventDefault();

        setProgress(true);
        setFilesError([]);

        const maxFileSize = maximumSize * 1024 * 1024; // pengaturan max size file upload
        const totalFileSize = form.files.reduce((total, file) => total + file.size, 0);

        if (totalFileSize > maxFileSize) {
            saAlert("error", "Ukuran total file melebihi batas maksimal.", `Ukuran total file: ${bytesToSize(totalFileSize)}, Batas maksimal: ${maximumSize} MB`);
            return;
        }

        const totalFile = files.length;

        if (totalFile > maximumFile) {
            saAlert("error", "File melebihi batas maksimal.", `Total file: ${totalFile} file, Batas maksimal: ${maximumFile} file`);
            return;
        }

        try {
            // Iterate over each file in the form data
            const uploadPromises = form.files.map(async (file) => {
                // Create new FormData instance for each file
                const formData = new FormData();
                formData.append('tahun', form.tahun);
                formData.append('files[]', file);

                // Upload the file with progress tracking
                const res = await postUploadJobdesk(formData, {
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        const progress = Math.round((loaded * 100) / total);

                        // Update progress for the corresponding file
                        setFileUploadProgress(prevState => ({
                            ...prevState,
                            [file.name]: progress
                        }));
                    }
                });

                return res;
            });

            // Wait for all file uploads to complete
            const responses = await Promise.all(uploadPromises);

            // Check if all uploads were successful
            const isSuccess = responses.every(res => res.data?.status === "success");

            if (isSuccess) {
                // Filter files that have incorrect format names
                const incorrectFormatFiles = responses.flatMap(res => res.data?.failed_uploads);

                // Remove incorrect format files from the form state
                const updatedFiles = form.files.filter(file => !incorrectFormatFiles.includes(file.name));

                setFilesError(incorrectFormatFiles);

                // Update form state to exclude incorrect format files
                setFiles(updatedFiles);
                setForm(prevForm => ({
                    ...prevForm,
                    files: updatedFiles
                }));

                setProgress(false);
                onFetchData();

                saAlert("warning", "Data tersimpan, namun...", "Ada beberapa file dengan format nama yang salah, cek logs.");

                // Jika tidak ada file yang gagal diunggah, tutup modal dan tampilkan pesan sukses
                if (incorrectFormatFiles.length === 0) {
                    handleClose();
                    onFetchData();
                    saAlert("success", "Data tersimpan.", "File anda tersimpan dengan sempurna.");
                }
            }
        } catch (error) {
            setProgress(false);
            setErrors(error.response?.data?.errors);
        }
    };



    // console.log(form);

    return (
        <>
            {/* <LayoutAddBtn onClick={handleOpen} add="Upload Slip Gaji" /> */}
            <button onClick={() => setModal(true)} type="button" className='w-100 mb-3 btn btn-sm btn-primary-2 rounded-3 px-4'><i className='fa fa-fw fa-arrow-up-from-bracket' /> {uploadName}</button>

            <LayoutModal
                title={uploadName}
                modal={modal}
                onClose={handleClose}
            >
                <h3 className='mb-3 pb-3 border-bottom border-silver fw-semibold text-primary'>{uploadName}</h3>

                <form ref={tagRef} onSubmit={handleSubmit} className='row'>
                    <div className='col-lg-4'>
                        <div className='position-sticky top-0'>
                            <FormSelect
                                label="Tahun"
                                name="tahun"
                                onChange={handleChange}
                                error={errors.tahun}
                            >
                                <option value="">Pilih</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                                <option value="2026">2026</option>
                                <option value="2027">2027</option>
                                <option value="2028">2028</option>
                                <option value="2030">2030</option>
                            </FormSelect>
                            <div>
                                <label className='fw-semibold text-primary'>Upload File</label>
                                <div {...getRootProps({ className: `${errors.files && 'text-danger'} w-100 py-5 d-flex justify-content-center align-items-center rounded-3`, style: { border: errors.files ? '2px dashed rgb(200, 0, 0)' : '2px dashed rgb(200, 200, 200)', cursor: 'pointer' } })}>
                                    <input {...getInputProps()} />
                                    <div className='px-5 text-center'>
                                        <i className='mb-4 fa fa-2xl fa-fw fa-file-pdf' />
                                        <p>Drag 'n' drop file pdf disini, atau klik untuk memilih file.</p>
                                        <p className='text-sm mb-0'>gunakan ' ctrl + mouse click ' untuk memilih banyak file pdf, maksimal ukuran file: {maximumSize} MB.</p>
                                    </div>
                                </div>
                                {errors.files && <p className='mt-2 text-danger text-sm'>{errors.files}</p>}
                                <div className='text-black mt-2 flex justify-content-between align-items-center'>
                                    <p className='mb-0'>Total File : <span className={files.length > maximumFile ? 'text-danger' : ''}>{files ? files.length : 0} / {maximumFile}</span></p>
                                    {files.length > maximumFile ? (
                                        <p className='mb-0 text-danger'>* File anda melebihi batas, Silahkan hapus beberapa file di antrean.</p>
                                    ) : ''}
                                    <p className='mb-0'>Total Size : <span className={totalSize > maximumSize * 1024 * 1024 ? 'text-danger' : ''}>{totalSize ? bytesToSize(totalSize) : '0 B'} / {maximumSize} MB</span></p>
                                    {totalSize > maximumSize * 1024 * 1024 ? (
                                        <p className='mb-0 text-danger'>* Anda mencapai batas maksimal ukuran file, Silahkan hapus beberapa file di antrean.</p>
                                    ) : ''}
                                </div>
                            </div>
                            <div className='mt-2 d-none d-lg-block'>
                                <NewSubmitButton disabled={files.length === 0} widthFull onClose={handleClose} />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-8'>
                        <label className='fw-semibold text-primary'>Logs</label>
                        {filesError.length > 0 ? (
                            <div>
                                <table className='table table-sm table-bordered'>
                                    <thead>
                                        <tr>
                                            <th className='bg-primary text-white text-black fw-normal text-center border-bottom-0 border-silver'>No</th>
                                            <th className='bg-primary text-white text-black fw-normal text-center border-bottom-0 border-silver'>Nama</th>
                                            <th className='bg-primary text-white text-black fw-normal text-center border-bottom-0 border-silver'>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filesError.map((fe, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className='align-middle text-center border-silver px-3'>{index + 1}</td>
                                                    <td className='align-middle border-silver px-3'>{fe}</td>
                                                    <td className='align-middle text-center text-nowrap border-silver px-3'><i className='fa fa-fw fa-circle-xmark text-danger me-1' /> Format nama salah.</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <p className='text-warning-2'>* Silahkan cek kembali nama file dan perbaiki, lalu upload ulang file yang bermasalah tersebut.</p>
                                <p className='text-warning-2'>* Anda dapat upload file yang sudah diperbaiki ke antrean file sebelumnya, tidak perlu takut jika file dengan nama yang sama di upload ulang karena tidak akan terjadinya file yang bertumpuk.</p>
                            </div>
                        ) : (
                            <div>
                                <table className='table table-sm table-bordered'>
                                    <tbody>
                                        <tr>
                                            <td className='border-silver px-3'>Tidak ada logs saat ini.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}
                        <label className='fw-semibold text-primary'>Antrean File</label>
                        {previewFile.length > 0 ? (
                            <table className='table table-sm table-bordered'>
                                <thead>
                                    <tr>
                                        <th className='bg-primary text-white text-black fw-normal text-center border-bottom-0 border-silver'>No</th>
                                        <th className='bg-primary text-white text-black fw-normal text-center border-bottom-0 border-silver'>Nama</th>
                                        <th className='bg-primary text-white text-black fw-normal text-center border-bottom-0 border-silver'>Size</th>
                                        <th className='bg-primary text-white text-black fw-normal text-center border-bottom-0 border-silver'><i className='fa fa-fw fa-cog' /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {previewFile}
                                </tbody>
                            </table>
                        ) : (
                            <div>
                                <table className='table table-sm table-bordered'>
                                    <tbody>
                                        <tr>
                                            <td className='border-silver px-3'>Belum ada file pdf yang di upload.</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div>
                                    <label className='fw-semibold text-primary'>Informasi</label>
                                    <ul className='text-black'>
                                        <li>File yang di upload harus type .pdf</li>
                                        <li>Selain file .pdf tidak akan masuk ke antrean file.</li>
                                        <li>Format nama file adalah nip_pegawai.pdf</li>
                                        <li>dengan contoh seperti : 2023092290.pdf</li>
                                    </ul>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='col-lg-12 row justify-content-center'>
                        <NewSubmitButton className="d-lg-none" onClose={handleClose} />
                    </div>
                </form>
            </LayoutModal>
        </>
    )
}

export default UploadJobdesk